/**
 * Switches workspace
 */
export class SetWorkspace {
    async run(client, args) {
        if (client.store.session.viewAs?.code === args.workspace) {
            return;
        }
        return {
            session: client.store.session.withViewAs(args.workspace)
        };
    }
}
;
