angular
    .module('klaro')
    .service('infoAndContactModal', infoAndContactModal)
    .controller('InfoAndContactModalController', InfoAndContactModalController);
function infoAndContactModal(klaroModal) {
    function open() {
        return klaroModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/apps/playground/modal/infoAndContactModal.html'),
            controller: 'InfoAndContactModalController',
            windowClass: 'klaro-modal info-and-contact-modal',
            size: 'medium',
        });
    }
    return { open: open };
}
function InfoAndContactModalController($scope, $uibModalInstance, shakeIt, $timeout, confirm, $window) {
    $scope.modes = {
        menu: {
            code: 'menu',
            title: "Welcome to Klaro Cards' Playground",
            menu: false,
            action: ($event, mode) => $scope.switchMode($event, mode),
        },
        examples: {
            code: 'examples',
            title: 'Explore a few examples',
            menu: true,
            action: ($event, mode) => $scope.switchMode($event, mode),
        },
        blank: {
            code: 'blank',
            title: 'Reset to a blank whiteboard',
            menu: true,
            action: ($event, mode) => $scope.resetToBlank($event),
        },
        contact: {
            code: 'contact',
            title: 'Contact us / Talk to a human',
            menu: true,
            action: ($event, mode) => $scope.switchMode($event, mode),
        },
        faq: {
            code: 'faq',
            title: 'Frequently Asked Questions',
            menu: true,
            action: ($event, mode) => $scope.switchMode($event, mode),
        },
    };
    $scope.modesInMenu = Object.values($scope.modes).filter(m => m.menu);
    $scope.mode = $scope.modes.menu;
    $scope.close = function ($event) {
        $uibModalInstance.dismiss();
        shakeIt($event, 'info-and-contact-button', 'animate-shake-xs');
    };
    $scope.switchMode = function ($event, mode) {
        $scope.mode = mode;
        if (mode.code === 'faq') {
            $timeout(subscribe);
        }
    };
    $scope.resetToBlank = function ($event) {
        return confirm({
            h2: 'Reset to a blank whiteboard',
            hint: 'All existing cards and dimensions will be lost.\n\nAre you sure?',
        }, async () => {
            $scope.close();
            window.location.href = `${$window.location.protocol}//${$window.location.hostname}/?reset=blank`;
        });
    };
    $scope.examples = [
        {
            code: 'todolist',
            title: 'Todolist',
            summary: 'Track personal work with a surprising simple process',
        },
        {
            code: 'eisenhower',
            title: 'Eisenhower matrix',
            summary: 'Urgence and importance done right',
        },
        {
            code: 'teamWork',
            title: 'Team work',
            summary: 'Organise a few team tasks the easy way',
        },
    ];
    $scope.selectedExample = null;
    $scope.selectExample = function (example) {
        $scope.selectedExample = example;
    };
    $scope.loadSelectedExample = function () {
        return confirm({
            h2: 'Load an example',
            hint: 'All existing cards and dimensions will be lost.\n\nAre you sure?',
        }, async () => {
            $scope.close();
            const code = $scope.selectedExample.code;
            window.location.href = `${$window.location.protocol}//${$window.location.hostname}/?reset=${code}`;
        });
    };
    // Function to subscribe to events
    function subscribe() {
        document.querySelectorAll('.info-and-contact-modal details').forEach(details => {
            details.addEventListener('toggle', () => {
                if (details.open) {
                    document.querySelectorAll('.info-and-contact-modal details').forEach(otherDetails => {
                        if (otherDetails !== details && otherDetails.open) {
                            otherDetails.open = false;
                        }
                    });
                }
            });
        });
    }
}
