import { LocalStoriesConnector } from './LocalStoriesConnector';
import { ApiBoardConnector } from '../api/ApiBoardConnector';
export class LocalBoardConnector extends ApiBoardConnector {
    constructor(connector, client, data) {
        super(client);
        this.connector = connector;
        this.client = client;
        this.data = data;
    }
    single(id) {
        const connector = this.connector;
        const client = this.client;
        const data = this.data;
        const superBoardSingle = super.single(id);
        return {
            stories: new LocalStoriesConnector(connector, client, data, `/boards/${id}`),
            exports: {
                async post() {
                    throw new Error('not implemented');
                }
            },
            async get(params) {
                let board;
                try {
                    board = await data.boards.get(id);
                }
                catch (err) {
                    board = await superBoardSingle.get(params);
                    data.boards.addOriginals([board]);
                }
                const rewrittenDimensions = await data.dimensions.values();
                if (rewrittenDimensions && rewrittenDimensions.length > 0) {
                    board.dimensions = rewrittenDimensions;
                }
                return board;
            },
            async patch(patch) {
                patch = { ...patch, id: id };
                await data.boards.store([patch]);
                return await this.get();
            },
            delete() {
                throw new Error('Method not implemented.');
            }
        };
    }
    search(_filters) {
        throw new Error('Method not implemented.');
    }
    get(_params) {
        throw new Error('Method not implemented.');
    }
    create(_resources) {
        throw new Error('Method not implemented.');
    }
    delete(_params) {
        throw new Error('Method not implemented.');
    }
    patch(_patch) {
        throw new Error('Method not implemented.');
    }
}
