/* eslint-disable angular/window-service */
/* eslint-disable angular/document-service */
/* eslint-disable angular/no-run-logic */
angular
    .module('klaro.playground')
    .run(['KernelClient', function (KernelClient) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://scripts.simpleanalyticscdn.com/latest.js');
        document.head.appendChild(script);
        const ignore = [
            'SetBoardState',
        ];
        KernelClient.instance.subscribe('*', (op, _args) => {
            if (!window.sa_event) {
                return;
            }
            const event = op.constructor.name;
            if (ignore.includes(event)) {
                return;
            }
            window.sa_event(`op_${event}`);
        });
    }]);
