import { Board, Project } from '../../../model';
import { ApiMyConnector } from '../api/ApiMyConnector';
import { ApiPinnedStoriesConnector } from '../api/ApiPinnedStoriesConnector';
export class LocalMyConnector {
    constructor(connector, client, data) {
        this.connector = connector;
        this.client = client;
        this.data = data;
        const apiConnector = new ApiMyConnector(client);
        this.projects = {
            async get() {
                const originalProjects = await apiConnector.projects.get();
                await data.projects.addOriginals(originalProjects);
                const projects = await data.projects.values();
                return projects.map(Project.dress);
            }
        };
        this.boards = {
            async get() {
                const originalBoards = await apiConnector.boards.get();
                await data.boards.addOriginals(originalBoards.map(o => o.toRaw()));
                const boards = await data.boards.values();
                return boards.map(Board.dress);
            }
        };
        this.preferences = {
            async patch(_preferences) {
                throw new Error('LocalMyConnector.preferences.patch is not implemented');
            }
        };
        this.stories = {
            pinned: new ApiPinnedStoriesConnector(client)
        };
    }
}
