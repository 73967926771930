angular
    .module('klaro')
    .directive('presenterMenu', presenterMenu);
function presenterMenu(ActionsForHelper) {
    return {
        restrict: 'E',
        template: require('@/core/board/presenterMenu.html'),
        require: '^board',
        scope: true,
        replace: true,
        link: function (scope, elm, attrs, boardCtrl) {
            boardCtrl.$watch('board', () => {
                scope.actions = ActionsForHelper.actionsForPresenterMenu(boardCtrl);
            }, scope);
            scope.labelFor = function (action) {
                if (typeof action.label === 'function') {
                    return action.label();
                }
                else {
                    return action.label;
                }
            };
            scope.executeIt = function ($event, method, param) {
                if (typeof method === 'function') {
                    method($event, param);
                }
            };
            scope.cssClassFor = function (action) {
                return [action.icon, action.separator ? 'separator' : null].filter(Boolean);
            };
            scope.enabled = function (action) {
                if (action.enabled === undefined) {
                    return true;
                }
                else {
                    return action.enabled(action.param);
                }
            };
        },
    };
}
