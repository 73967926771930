import { v4 } from 'uuid';
export const eisenhower = async () => {
    return {
        board: {
            id: 'default',
            location: 'default',
            connector: 'BaseConnector',
            label: 'Eisenhower matrix',
            mode: 'matrix',
            filters: {},
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            background: 'default',
            objective: '',
            objectiveModal: false,
            decksOpen: true,
            compactDecks: false,
            explorerDecks: false,
            manualDimensionsInDecks: false,
            compactDisplay: false,
            summaries: [],
            anchors: {
                displayBy: [
                    'urgency'
                ],
                rowsBy: [
                    'importance'
                ],
                required: [
                    'urgency',
                    'importance'
                ]
            },
            coloredDimension: null,
            dimensions: [
                {
                    id: '5556d77c-54db-4f86-8bf3-7665ca963936',
                    code: 'importance',
                    label: 'Importance',
                    datatype: 'UserDefined',
                    datatypeOptions: {},
                    required: true,
                    multiple: false,
                    tagging: false,
                    supportsColor: true,
                    values: [
                        {
                            id: null,
                            label: 'No value',
                            semantics: null,
                            color: '#ffffff',
                            ordering: 0,
                            deprecated: false
                        },
                        {
                            id: 'important',
                            label: 'Important',
                            color: '#00b048',
                            ordering: 0,
                            deprecated: false
                        },
                        {
                            id: 'not-important',
                            label: 'Not important',
                            color: '#e01514',
                            ordering: 100000,
                            deprecated: false
                        }
                    ],
                    semanticsType: null,
                    ordering: 0,
                    deleted: false,
                    deletedAt: null,
                    deletedBy: null,
                    description: '',
                    relevantKinds: [],
                    attribute: false,
                    supportsMultiple: true,
                    supportsRequired: true,
                    supportsTagging: true,
                    supportsAlphabeticalOrdering: false,
                    userEditable: true
                },
                {
                    id: 'a484ca06-f80f-4eec-aab0-4fa2c6c58f17',
                    code: 'urgency',
                    label: 'Urgency',
                    datatype: 'UserDefined',
                    datatypeOptions: {},
                    required: true,
                    multiple: false,
                    tagging: false,
                    supportsColor: true,
                    values: [
                        {
                            id: null,
                            label: 'No value',
                            semantics: null,
                            color: '#ffffff',
                            ordering: 0,
                            deprecated: false
                        },
                        {
                            id: 'urgent',
                            label: 'Urgent',
                            color: '#00b048',
                            ordering: 0,
                            deprecated: false
                        },
                        {
                            id: 'not-urgent',
                            label: 'Not urgent',
                            color: '#e01514',
                            ordering: 100000,
                            deprecated: false
                        }
                    ],
                    semanticsType: null,
                    ordering: 1000000,
                    deleted: false,
                    deletedAt: null,
                    deletedBy: null,
                    description: '',
                    relevantKinds: [],
                    attribute: false,
                    supportsMultiple: true,
                    supportsRequired: true,
                    supportsTagging: true,
                    supportsAlphabeticalOrdering: false,
                    userEditable: true
                }
            ],
            ganttScale: 'month',
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {}
                }
            },
            workspacePermissions: [
                {
                    workspace: 'admins',
                    canManage: true,
                    canRead: true,
                    canWrite: true
                }
            ],
            ordering: 1000000,
            createdBy: 'blambeau',
            createdAt: '2024-12-20T11:15:54.720Z'
        },
        stories: [
            {
                id: v4(),
                identifier: 1,
                title: 'Start exploring Klaro Cards\nUntil you are sure it\'s the best tool for you',
                importance: 'important',
                urgency: 'urgent',
            }
        ],
    };
};
