import { blank } from './blank';
import { ecosystem } from './ecosystem';
import { houseRenovation } from './houseRenovation';
import { onboarding } from './onboarding';
import { teamWork } from './teamWork';
import { todolist } from './todolist';
import { eisenhower } from './eisenhower';
export const examples = {
    blank,
    ecosystem,
    houseRenovation,
    onboarding,
    teamWork,
    todolist,
    eisenhower,
};
