angular
    .module('klaro')
    .directive('seshatDropZone', seshatDropZone);
function seshatDropZone(seshat, Config, failedModal, events, $filter, $timeout) {
    return {
        restrict: 'A',
        template: require('@/support/seshat/seshatDropZone.html'),
        transclude: true,
        scope: {
            seshatDropZoneEnabled: '=',
            seshatDropZoneCallback: '&',
        },
        controller: function ($scope) {
            this.getSeshatScope = function () {
                return $scope;
            };
        },
        link: function (scope, elm, attrs) {
            const single = attrs.seshatDropZoneMode === 'single';
            const maxSize = Config.upload.maxSize;
            const maxConcurrentUploads = Config.upload.maxConcurrentUploads;
            scope.upload = { files: null };
            scope.isMultiple = !single;
            scope.includeButton = attrs.seshatDropZoneButton === '';
            scope.$watch('upload.files', (files) => {
                if (!files || files.length === 0) {
                    return;
                }
                if (!files.length) {
                    files = [files];
                }
                if (single && files.length > 1) {
                    elm.find('.dropzone.dropped')[0].classList.remove('dropped');
                    return failedModal.open({
                        title: 'Too many files',
                        report: false,
                        description: 'This feature does not support multiple files.',
                    });
                }
                if (files.length > maxConcurrentUploads) {
                    elm.find('.dropzone.dropped')[0].classList.remove('dropped');
                    return failedModal.open({
                        title: 'Too many files',
                        report: false,
                        description: `We only accept ${maxConcurrentUploads} uploaded files at a time.`,
                    });
                }
                const tooBig = files.find((f) => {
                    return f.size > maxSize;
                });
                if (tooBig) {
                    const dropZoneElements = elm.find('.dropzone.dropped');
                    if (dropZoneElements.length > 0) {
                        dropZoneElements[0].classList.remove('dropped');
                    }
                    return failedModal.open({
                        title: `${tooBig.name} is too big.`,
                        report: false,
                        description: `Max size accepted is ${$filter('fileSize')(maxSize)}`,
                    });
                }
                const ps = files.map((f) => {
                    const data = {};
                    data[f.name] = f;
                    return seshat({ data })
                        .then(fileInfo => fileUploaded(fileInfo, f.size));
                });
                const promise = Promise.all(ps).then((fs) => {
                    updateDropzoneStyle('dropped', 'uploaded', '.dropped');
                    return single ? fs[0] : fs;
                });
                $timeout(() => {
                    updateDropzoneStyle('uploaded');
                }, 1000);
                scope.seshatDropZoneCallback({ promise: promise });
            });
            function fileUploaded(fileInfo, fileSize) {
                return {
                    filename: fileInfo.fileName,
                    url: fileInfo.relative,
                    description: '',
                    sizeInBytes: fileSize, // @TODO seshat should send this info
                };
            }
            const inBoard = elm.closest('board-content');
            const watchElm = attrs.seshatDropZoneElm
                ? elm.closest(attrs.seshatDropZoneElm)[0]
                : elm[0];
            events.observeElement(scope, 'dragover', dragOver, watchElm);
            events.observeElement(scope, 'dragenter', dragOver, watchElm);
            events.observeElement(scope, 'dragend', dragOut, watchElm);
            events.observeElement(scope, 'dragleave', dragOut, watchElm);
            events.observeElement(scope, 'drop', dropHandler, watchElm);
            events.observeElement(scope, 'paste', pasteHandler, watchElm);
            function dragOver(e) {
                if (!e.dataTransfer.types.includes('Files')) {
                    return;
                }
                if (!scope.seshatDropZoneEnabled) {
                    return;
                }
                e.preventDefault();
                // There is a bug when hovering too fast, the dragleave event is not fired
                // Thus this hack removes the "dragging" class from other elements.
                if (inBoard.length) {
                    const draggingZones = inBoard.find('.dropzone.dragging');
                    if (draggingZones.length > 1) {
                        Object.values(draggingZones).forEach(dz => {
                            if (dz.classList) {
                                dz.classList.remove('dragging');
                                dz.classList.remove('uploaded');
                            }
                        });
                    }
                }
                updateDropzoneStyle(null, 'dragging');
            }
            function dragOut(e) {
                if (!e.dataTransfer.types.includes('Files')) {
                    return;
                }
                if (!scope.seshatDropZoneEnabled) {
                    return;
                }
                e.preventDefault();
                if (e.target.classList.contains('dropzone')) {
                    updateDropzoneStyle('dragging');
                }
            }
            function dropHandler(e) {
                if (!e.dataTransfer.types.includes('Files')) {
                    return;
                }
                if (!scope.seshatDropZoneEnabled) {
                    return;
                }
                e.preventDefault();
                updateDropzoneStyle('dragging', 'dropped');
                scope.upload.files = Array.from(e.dataTransfer.files);
                scope.$apply();
            }
            function pasteHandler(e) {
                if (!e.dataTransfer) {
                    return;
                }
                if (!e.dataTransfer.types.includes('Files')) {
                    return;
                }
                if (!scope.seshatDropZoneEnabled) {
                    return;
                }
                e.preventDefault();
                scope.upload.files = Array.from(e.clipboardData.files);
                scope.$apply();
            }
            function updateDropzoneStyle(remove, add, subClass = '') {
                const dropZone = elm.find(`.dropzone${subClass}`)[0];
                if (!dropZone) {
                    return;
                }
                if (remove) {
                    dropZone.classList.remove(remove);
                }
                if (add) {
                    dropZone.classList.add(add);
                }
            }
        },
    };
}
