angular
    .module('klaro')
    .directive('storyInfo', storyInfo);
function storyInfo() {
    return {
        restrict: 'E',
        template: require('@/core/story/storyInfo.html'),
        scope: {
            story: '=',
            storyData: '=',
        },
        link: function (scope, elm) {
            const main = elm.closest('.story-anchors').find('story-big-form .left');
            scope.scrollToAttachments = function () {
                const container = main.find('.attachment-container')[0];
                if (!container) {
                    return;
                }
                main.find('.section-details')[0].scroll({ top: container.offsetTop - 100 });
            };
            scope.scrollToCardLinks = function () {
                const container = main.find('.linked-stories-container')[0];
                if (!container) {
                    return;
                }
                main.find('.section-details')[0].scroll({ top: container.offsetTop - 100 });
            };
        },
    };
}
