/**
 * Adds a parsing rule to markdown-it for parsing story references,
 * i.e. `#56`.
 */
function StoryRefParser(state, silent) {
    const max = state.posMax;
    let ch, pos = state.pos, start = pos, end = pos, token;
    // It needs to start with a '#'
    if (pos >= max || state.src.charCodeAt(pos) !== 0x23 /* # */) {
        return false;
    }
    pos += 1;
    // Parse every digit following it
    start = pos;
    while (pos < max) {
        ch = state.src.charCodeAt(pos);
        if (ch < 0x30 || ch > 0x39) {
            break;
        }
        pos++;
    }
    end = pos;
    // At least one digit is required in practice
    if (end === start) {
        return false;
    }
    // The next character must not be a letter
    ch = state.src.charCodeAt(end);
    if (end < max && ch >= 0x41 && ch <= 0x7A) {
        return false;
    }
    if (!silent) {
        const storyRef = state.src.slice(start, end);
        token = state.push('link_open', 'a', 1);
        token.attrs = [['href', `/cards/${storyRef}`]];
        token.markup = 'story-ref';
        token.info = 'auto';
        token = state.push('text', '', 0);
        token.content = `#${storyRef}`;
        token = state.push('link_close', 'a', -1);
        token.markup = 'story-ref';
        token.info = 'auto';
    }
    state.pos = end;
    return true;
}
/**
 * Install the parsing rule on `md`.
 *
 * This method is intended to be used with markdown-it's `use`, i.e.
 * `(require('markdown-it')()).use(...)`
 */
function MarkdownExt(md) {
    md.inline.ruler.after('emphasis', 'card-reference', StoryRefParser);
}
export default MarkdownExt;
