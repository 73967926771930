export class PatchableStorage {
    constructor(originals, patches) {
        this.originals = originals;
        this.patches = patches;
    }
    async addOriginals(originals) {
        for (const original of originals) {
            this.originals.set(original.id, original);
        }
        return this;
    }
    async values() {
        const patches = await this.patches.entries();
        const patchedP = [...await this.originals.entries()]
            .map(async ([id, t]) => {
            const patch = await this.patches.get(id);
            return [id, {
                    ...t,
                    ...patch
                }];
        });
        const patched = await Promise.all(patchedP);
        const patchedMap = new Map(patched);
        // We then add patches not matching original resources
        const withLocal = patches.reduce((map, t) => {
            if (!map.has(t.id)) {
                map.set(t.id, t);
            }
            return map;
        }, patchedMap);
        return [...withLocal.values()];
    }
    async get(id) {
        const original = await this.originals.get(id);
        const patch = await this.patches.get(id);
        if (!original && !patch) {
            throw 'Unable to find item with id #{id}';
        }
        return {
            ...original,
            ...patch
        };
    }
    /**
     * A delete only deletes from the .
     */
    async delete(id) {
        await this.originals.delete(id);
        await this.patches.deleteByID(id);
    }
    async store(patches) {
        const results = await Promise.all(patches.map(async (patch) => {
            const original = await this.originals.get(patch.id);
            const existingPatch = await this.patches.get(patch.id);
            const completePatch = {
                ...existingPatch,
                ...patch,
            };
            const resultingT = {
                ...original,
                ...completePatch
            };
            return [completePatch, resultingT];
        }));
        const toStoreAsPatches = results.map(([p]) => p);
        const resultingTs = results.map(([_, t]) => t);
        await this.patches.store(toStoreAsPatches);
        return resultingTs;
    }
}
