export class LocalStorageStore {
    async get(key) {
        const itemStr = localStorage.getItem(key);
        if (itemStr) {
            return JSON.parse(itemStr);
        }
        else {
            return null;
        }
    }
    async set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    async remove(key) {
        localStorage.removeItem(key);
    }
    clear() {
        localStorage.clear();
    }
}
