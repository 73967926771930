angular
    .module('klaro')
    .controller('ViewStoryController', ViewStoryController);
function ViewStoryController($scope, $rootScope, board, $routeParams, navigation, Title) {
    $rootScope.view = 'story';
    $scope.board = board;
    $scope.$watch('boardCtrl', async (boardCtrl) => {
        if (!boardCtrl || $scope.story) {
            return;
        }
        try {
            const id = $routeParams.id;
            const identifier = parseInt(id);
            $scope.story = await $scope.boardCtrl.loadStoryDetails(identifier, false);
            Title.setCurrentPage($scope.story.rawTitle);
            $scope.boardCtrl.openFullEditStoryModal($scope.story, {
                noStoryHash: true,
                noClose: true,
                noCardTools: true,
            });
        }
        catch (ex) {
            navigation.failed(ex);
        }
    });
}
