import { Dimension } from '../../../model';
export class LockDimension {
    async run(client, args) {
        const dim = args.dimension.toRaw();
        const patch = {
            id: dim.id,
            tagging: false,
        };
        const data = await client.store.connector.dimensions.single(dim.id).patch(patch);
        this._result = Dimension.dress({ ...dim, ...data });
    }
    get result() {
        return this._result;
    }
}
;
