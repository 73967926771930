import { LocalBoardConnector } from './LocalBoardConnector';
import { LocalStoriesConnector } from './LocalStoriesConnector';
import { MemoryPatchStorage } from './';
import { LocalAuthenticationConnector } from './LocalAuthenticationConnector';
import { LocalMyConnector } from './LocalMyConnector';
import { LocalProjectConnector } from './LocalProjectConnector';
import { PatchableStorage } from './PatchableStorage';
import { LocalSeshatConnector } from './LocalSeshatConnector';
import { LocalSummariesConnector } from './LocalSummariesConnector';
import { LocalDimensionsConnector } from './LocalDimensionsConnector';
import { LocalInstallersConnector } from './LocalInstallersConnector';
export class LocalConnector {
    constructor(client, options) {
        this.capabilities = {
            cards: {
                fullDetails: false,
                url: false,
                coverImage: false,
                attachments: false,
                actions: {
                    archive: false,
                    pin: false
                },
            },
            dimensions: {
                options: false,
                actions: {
                    disable: false,
                    delete: true,
                }
            },
            boards: {
                actions: {
                    save: false,
                    delete: false,
                },
            },
        };
        //
        this.feedStrategy = 'api';
        this.patchStorage = options?.patchStorage || {
            storyPatches: new MemoryPatchStorage(),
            dimensionPatches: new MemoryPatchStorage(),
            boardsPatches: new MemoryPatchStorage(),
            projectsPatches: new MemoryPatchStorage(),
        };
        this.feedStrategy = options?.feedStrategy || 'api';
        this.store = {
            stories: new PatchableStorage(new Map(), this.patchStorage.storyPatches),
            boards: new PatchableStorage(new Map(), this.patchStorage.boardsPatches),
            projects: new PatchableStorage(new Map(), this.patchStorage.projectsPatches),
            dimensions: new PatchableStorage(new Map(), this.patchStorage.dimensionPatches),
            dimensionValues: new Map(),
        };
        this.boards = new LocalBoardConnector(this, client, this.store);
        this.stories = new LocalStoriesConnector(this, client, this.store);
        this.dimensions = new LocalDimensionsConnector(this, client, this.store);
        this.auth = new LocalAuthenticationConnector(client);
        this.my = new LocalMyConnector(this, client, this.store);
        this.project = new LocalProjectConnector(this, client, this.store);
        this.summaries = new LocalSummariesConnector(this, client, this.store);
        this.installers = new LocalInstallersConnector(this, client, this.store);
        this.seshat = new LocalSeshatConnector(this, client);
    }
    feedFrom(example) {
        this.store.boards.store([example.board]);
        this.store.dimensions.store(example.board.dimensions);
        this.store.stories.store(example.stories);
        return this;
    }
    async clearAll() {
        await this.patchStorage.storyPatches.clear();
        await this.patchStorage.dimensionPatches.clear();
        await this.patchStorage.boardsPatches.clear();
        await this.patchStorage.projectsPatches.clear();
        return this;
    }
}
