import { LoadBoard } from '@klaro/corejs/client';
import { DisplayModes } from '@klaro/corejs/model';
angular
    .module('klaro.playground')
    .config(['$routeProvider', '$locationProvider',
    function ($routeProvider, $locationProvider) {
        const KernelClient = angular.injector(['ng', 'klaro.kernel']).get('KernelClient');
        const loadBoard = async (boardId) => {
            await KernelClient.run(new LoadBoard(), {
                boardId: boardId,
                preparer: (bs) => {
                    return bs.with({
                        board: bs.board
                            .withAvailableDisplayModes(DisplayModes.filter(m => m.includeInPlayground))
                            .withExtraSettings({
                            onCardClick: {
                                type: 'quickEdit',
                                options: {},
                            },
                        }),
                    });
                },
            });
            return KernelClient.store.board;
        };
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
        });
        $routeProvider
            .when('/', {
            isPublic: true,
            template: require('@/apps/playground/home/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            resolve: {
                project: async () => KernelClient.store.project,
                board: async () => loadBoard('default'),
            },
        })
            .when('/exemples/:example', {
            isPublic: true,
            template: require('@/apps/playground/home/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            resolve: {
                project: async () => KernelClient.store.project,
                board: ['$route',
                    function ($route) {
                        return loadBoard($route.current.params.example);
                    },
                ],
            },
        })
            .when('/x/:example/:board', {
            isPublic: true,
            template: require('@/apps/playground/home/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            resolve: {
                project: async () => KernelClient.store.project,
                board: ['$route',
                    function ($route) {
                        return loadBoard($route.current.params.board);
                    },
                ],
            },
        })
            .otherwise({
            redirectTo: '/',
        });
    }]);
