angular
    .module('klaro')
    .factory('authInterceptor', authInterceptor);
function authInterceptor($q, $log, $injector, $window) {
    return {
        /**
         * Intercepts HTTP requests to the API and set the authorization
         * token if one is currently known on the `auth` module and the
         * request does not already have one.
         */
        request: function (request) {
            const skip = request.skipKlaroAuth || false;
            if (skip) {
                return request;
            }
            const auth = $injector.get('auth');
            const token = auth.getToken();
            if (token && !request.headers.Authorization) {
                request.headers.Authorization = `${token.token_type} ${token.access_token}`;
            }
            const viewAs = auth.getViewAs();
            if (viewAs && !request.headers['X-Klaro-ViewAs']) {
                request.headers['X-Klaro-ViewAs'] = viewAs.code;
            }
            return request;
        },
        /**
         * Intercepts HTTP errors to the API and forces a login if a 401 status
         * is observed.
         *
         * This should rarely occur, since the kernel and auth module are written in
         * such a way that tokens are validated before being used. Still, if the backend
         * decides that a validated token is no longer valid, this may still happen,
         * rarely.
         */
        responseError: function (response) {
            var _a;
            const skip = (_a = response.config).skipAuthErrorHandling || (_a.skipAuthErrorHandling = false);
            const auth = $injector.get('auth');
            if (skip && typeof skip === 'boolean') {
                return $q.reject(response);
            }
            if ((!skip || !skip.includes(401)) && response.status === 401) {
                if (!auth.isTokenSpecial()) {
                    $log.info('Whoops, request was rejected... redirected to login page');
                    auth.loginPage(true);
                }
            }
            else if ((!skip || !skip.includes(402)) && response.status === 402) {
                $window.location = '/blocked.html';
            }
            else if ((!skip || !skip.includes(403)) && response.status === 403) {
                auth.forbiddenPage(true);
            }
            else if ((!skip || !skip.includes(404)) && response.status === 404) {
                if (response.config.url.indexOf('/api') === 0) {
                    $injector.get('navigation').gotoUrl('/not-found');
                }
            }
            return $q.reject(response);
        },
    };
}
