import { Attachment } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('attachments', attachments);
function attachments(confirm, $location, Clipboard, failedModal, $timeout, Lightbox, SecureDownload, attachmentsService, robust) {
    return {
        restrict: 'E',
        template: require('@/core/story/attachments.html'),
        scope: {
            inBoard: '=',
            story: '=',
            storyData: '=',
            readOnly: '=',
            onChange: '&',
        },
        link: function (scope) {
            scope.isNewStory = null;
            scope.attachments = [];
            function onLoad() {
                if (!scope.story) {
                    return;
                }
                scope.attachments = scope.story.getAttachments();
                scope.isNewStory = !scope.story.id;
            }
            onLoad();
            scope.$watch('story', onLoad);
            function storyChanged() {
                return scope.onChange();
            }
            scope.deleteAttachment = function ($event, attachment) {
                $event.preventDefault();
                $event.stopPropagation();
                confirm({
                    h2: 'Delete attachment',
                    hint: 'Are you sure you want to delete this attachment?',
                }, () => {
                    if (scope.isNewStory) {
                        scope.storyData.attachments = scope.storyData.attachments.filter(a => {
                            return attachment.url !== a.url;
                        });
                        scope.attachments = scope.storyData.attachments.map(Attachment.dress);
                    }
                    else {
                        attachmentsService
                            .drop(scope.inBoard, scope.story, attachment)
                            .then(storyChanged)
                            .catch(onError);
                    }
                });
            };
            scope.copyToClipboardText = 'Copy link to attachment';
            scope.attachmentLink = function ($event, attachmentUrl) {
                $event.preventDefault();
                $event.stopPropagation();
                Clipboard
                    .copy(new URL(attachmentUrl, $location.absUrl()).href)
                    .then(() => {
                    $event.target.innerHTML = 'Copied!';
                    $timeout(() => {
                        $event.target.innerHTML = scope.copyToClipboardText;
                    }, 2000);
                });
            };
            scope.downloadAttachment = function ($event, attachment) {
                $event.preventDefault();
                $event.stopPropagation();
                const url = new URL(attachment.url, $location.absUrl()).href;
                SecureDownload.getBlob(url, {
                    name: attachment.filename,
                });
            };
            scope.handleClick = function ($event, attachment) {
                if (attachment.isImage) {
                    const images = scope.attachments
                        .filter(a => a.isImage)
                        .map(a => Object.assign({ caption: a.filename }, a.toRaw()));
                    const index = images.findIndex((a) => {
                        return (a.id === attachment.id);
                    });
                    Lightbox.openModal(images, index);
                }
                else {
                    scope.downloadAttachment($event, attachment);
                }
            };
            scope.isCoverText = function (attachment) {
                return attachment.isCover ? 'Remove cover' : 'Set as cover';
            };
            scope.canCover = function (attachment) {
                return attachmentsService.canCover(attachment);
            };
            scope.makeCover = function (attachment) {
                if (scope.isNewStory) {
                    scope.storyData.attachments.forEach(a => a.isCover = (a.url === attachment.url));
                    scope.attachments = scope.storyData.attachments.map(Attachment.dress);
                }
                else {
                    return attachmentsService
                        .makeCover(scope.inBoard, scope.story, attachment)
                        .then(storyChanged)
                        .catch(onError);
                }
            };
            scope.dropCover = function () {
                if (scope.isNewStory) {
                    scope.storyData.attachments.forEach(a => a.isCover = false);
                    scope.attachments = scope.storyData.attachments.map(Attachment.dress);
                }
                else {
                    return attachmentsService
                        .dropCover(scope.inBoard, scope.story)
                        .then(storyChanged)
                        .catch(onError);
                }
            };
            scope.toggleCover = function ($event, attachment) {
                $event.preventDefault();
                $event.stopPropagation();
                if (attachment.isCover) {
                    scope.dropCover();
                }
                else {
                    scope.makeCover(attachment);
                }
            };
            scope.fileUploaded = function (promise) {
                return promise
                    .then((files) => {
                    if (scope.isNewStory) {
                        const attachments = scope.storyData.attachments || [];
                        scope.storyData.attachments = attachments.concat(attachmentsService.attachmentsDataFor(scope.story, files, attachments.length === 0));
                        scope.attachments = scope.storyData.attachments.map(Attachment.dress);
                    }
                    else {
                        attachmentsService
                            .add(scope.inBoard, scope.story, files)
                            .then(storyChanged);
                    }
                })
                    .catch(onError);
            };
            function onError(error) {
                const message = robust.message(error);
                return failedModal.open({
                    title: 'Oups',
                    description: message,
                });
            }
        },
    };
}
