import { Klarifier } from '@klaro/corejs/klarifier';
angular
    .module('klaro')
    .service('quickEditStoryModal', quickEditStoryModal)
    .controller('StoryCreateModalController', StoryCreateModalController)
    .directive('textAreaWithTab', TextAreaWithTab);
function quickEditStoryModal(klaroModal) {
    return {
        open: function (boardCtrl, story, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/quickEditStoryModal.html'),
                controller: 'StoryCreateModalController',
                windowClass: options?.isBulk ? 'klaro-modal bulk-create-story-modal' : 'klaro-modal quick-edit-story-modal',
                size: options?.isBulk ? 'medium' : 'medium full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    story: function () {
                        return story;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StoryCreateModalController($scope, $uibModalInstance, boardCtrl, story, options, robust) {
    options = (typeof options === 'function') ? { onSubmit: options } : options;
    options.title || (options.title = options.isBulk ? 'Add multiple cards' : (options.isNew ? 'Add a card' : 'Edit card'));
    $scope.validDimensionsValues = options.validDimensionsValues;
    $scope.storyData = story.toRaw();
    $scope.initialData = angular.extend({}, $scope.storyData);
    $scope.dimensions = boardCtrl.getDimensions();
    $scope.options = options;
    $scope.saving = false;
    $scope.isRequired = function (dimension) {
        return boardCtrl.isRequired(dimension);
    };
    $scope.$watch('dimensions', (ds) => {
        if (!ds) {
            return;
        }
        // editable dimensions
        const eds = ds
            .filter(d => d.canBeEditedAsCardDimension() && $scope.isRelevant(d))
            .sort((d, e) => d.ordering - e.ordering);
        $scope.dimSections = [];
        if (eds.length <= 4) {
            $scope.dimSections.push({
                // to be defined
                legend: null,
                open: true,
                dimensions: eds,
            });
        }
        else {
            $scope.dimSections.push({
                // to be defined
                legend: 'You must fill those dimensions',
                open: true,
                dimensions: eds.filter((d) => boardCtrl.isRequired(d) && $scope.initialData[d.code] === undefined),
            });
            $scope.dimSections.push({
                // ambiguous dimensions
                legend: 'You card will disappear unless you fill these dimensions',
                open: true,
                dimensions: ds.filter((d) => !boardCtrl.isRequired(d) && boardCtrl.hasFilter(d) && $scope.initialData[d.code] === undefined),
            });
            const tbrDimensions = eds.filter((d) => {
                return $scope.initialData[d.code] !== undefined;
            });
            $scope.dimSections.push({
                // to be reviewed
                legend: `Dimension${tbrDimensions.length > 1 ? 's' : ''} already prefilled <span class="badge purple">${tbrDimensions.length}</span>`,
                open: !!options.openPrefilledSection,
                dimensions: tbrDimensions,
            });
        }
    });
    $scope.onValueCreated = function (dimension, value) {
        boardCtrl.addDimensionValue(dimension, value, true).then(() => {
            $scope.dimensions = boardCtrl.getDimensions();
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.canCreate = function () {
        return options.isNew && !options.isBulk && $scope.formIsValid() && !$scope.saving;
    };
    $scope.canBulkCreate = function () {
        return options.isNew && options.isBulk && $scope.formIsValid() && !$scope.saving;
    };
    $scope.canSave = function () {
        return !options.isNew && $scope.formIsValid() && !$scope.saving;
    };
    $scope.formIsValid = function () {
        return $scope.storyCreateForm.$valid
            && $scope.storyData.title
            && $scope.storyData.title.trim() !== '';
    };
    $scope.isRelevant = function (dim) {
        return dim.isRelevant($scope.storyData || {}, boardCtrl);
    };
    $scope.openDetails = function () {
        const story = boardCtrl.newStory($scope.storyData);
        const options = {
            isNew: true,
            editing: true,
        };
        boardCtrl.openFullEditStoryModal(story, options)
            .then($uibModalInstance.close)
            .catch($scope.cancel);
    };
    $scope.createCard = function () {
        if (!$scope.canCreate()) {
            return;
        }
        $scope.saving = true;
        options
            .onSubmit(boardCtrl.newStory($scope.storyData))
            .then($uibModalInstance.close)
            .finally(() => {
            $scope.saving = false;
        })
            .catch(onError);
    };
    $scope.createCards = function () {
        if (!$scope.canBulkCreate()) {
            return;
        }
        const klarified = Klarifier.klarifyString($scope.storyData.title, {
            splitSubtitle: options.splitSubtitle,
        });
        const stories = [...klarified.stories.values()].map(s => {
            return boardCtrl.newStory({ ...$scope.storyData, ...s });
        });
        $scope.saving = true;
        options
            .onSubmit(stories)
            .then($uibModalInstance.close)
            .finally(() => {
            $scope.saving = false;
        })
            .catch(onError);
    };
    $scope.saveCard = function () {
        if (!$scope.canSave()) {
            return;
        }
        $scope.saving = true;
        options
            .onSubmit(story.clone($scope.storyData), story)
            .then($uibModalInstance.close)
            .finally(() => {
            $scope.saving = false;
        })
            .catch(onError);
    };
    const kindDimension = boardCtrl.getKindDimension();
    $scope.$watch(`storyData.${kindDimension.code}`, () => {
        $scope.cardPlaceholder = options.isBulk ?
            "Paste anything you want, one card per line. We'll do some magic from here." :
            boardCtrl.getBoardState().getPlaceholderFor($scope.storyData);
    });
    function onError(err) {
        $scope.error = robust.message(err.data, false);
    }
}
function TextAreaWithTab() {
    return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
            const textarea = elm[0];
            textarea.addEventListener('keydown', (event) => {
                if (event.key === 'Tab') {
                    event.preventDefault(); // Prevent the default tab behavior (switching focus)
                    // Get the current position of the cursor
                    const start = textarea.selectionStart;
                    const end = textarea.selectionEnd;
                    // Insert a tab character (\t) at the cursor position
                    textarea.value = `${textarea.value.substring(0, start)}\t${textarea.value.substring(end)}`;
                    // Move the cursor after the tab character
                    textarea.selectionStart = textarea.selectionEnd = start + 1;
                }
            });
        },
    };
}
