import { SaveValue, CreateValue, DeleteValue } from '@klaro/corejs/client';
angular
    .module('klaro')
    .service('dimensionValueEditModal', dimensionValueEditModal)
    .controller('DimensionValueEditModalController', DimensionValueEditModalController);
function dimensionValueEditModal(klaroModal) {
    return {
        open: function (dimension, formData, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/dimension/dimensionValueEditModal.html'),
                controller: 'DimensionValueEditModalController',
                windowClass: 'klaro-modal center-modal',
                size: 'small',
                resolve: {
                    dimension: function () {
                        return dimension;
                    },
                    formData: function () {
                        return formData;
                    },
                    options: function () {
                        return options || {};
                    },
                },
            });
        },
    };
}
function DimensionValueEditModalController($scope, $uibModalInstance, dimension, formData, options, robust, Colors, navigation, storyRest, KernelClient) {
    $scope.dimension = dimension;
    $scope.formData = formData;
    $scope.deleteData = { 'onExisting': 'fail' };
    $scope.mode = options.mode || 'edit';
    $scope.replacingDimension = dimension.withoutValue(formData);
    $scope.cardColors = Colors.cards();
    $scope.isNew = !$scope.formData.id;
    $scope.title = $scope.isNew ? 'New value' : 'Edit value';
    $scope.hasDescription = $scope.formData.description;
    $scope.hasPlaceholder = $scope.formData.placeholder;
    $scope.focusOn = function (field) {
        return field === (options.focusOn || 'label');
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    function commit(dimensionValue) {
        $uibModalInstance.close(dimensionValue);
    }
    $scope.semanticsDocHref = function () {
        const cards = {
            DateRange: '273',
        };
        const card = cards[$scope.dimension.semanticsType];
        if (!card) {
            return false;
        }
        return `https://faq.klaro.cards/stories/${card}`;
    };
    $scope.semanticsLabel = function () {
        const labels = {
            Integer: 'Number',
            DateRange: 'Date interval',
            IntegerRange: 'Number interval',
        };
        return labels[$scope.dimension.semanticsType] || 'Semantics';
    };
    $scope.save = async function () {
        $scope.error = null;
        const args = {
            dimension: dimension,
            value: $scope.formData,
        };
        try {
            const op = $scope.formData.id ? SaveValue : CreateValue;
            const ranOp = await KernelClient.run(new op, args);
            const data = {
                event: $scope.formData.id ? 'updated' : 'created',
                dimensionValue: ranOp.result,
                dimension: $scope.replacingDimension.addValue(ranOp.result),
            };
            commit(data);
        }
        catch (err) {
            $scope.error = robust.message(err);
        }
    };
    $scope.moveToSourceToRemove = function () {
        const url = `/settings/${$scope.dimension.getValueDeletionPlace()}/`;
        navigation.gotoUrl(url, {
            q: $scope.formData.label,
        });
        $scope.cancel();
    };
    $scope.canDelete = function () {
        return ($scope.deleteData.onExisting !== 'replace')
            || !$scope.dimension.required
            || $scope.deleteData.replaceBy;
    };
    $scope.tryDelete = async function () {
        try {
            await deleter();
        }
        catch (err) {
            $scope.deleteData = { 'onExisting': 'replace' };
            $scope.mode = 'confirm-delete';
        }
    };
    $scope.delete = function () {
        return deleter()
            .catch(navigation.failed);
    };
    $scope.$watch('deleteData.replaceBy', (newV) => {
        if ($scope.deleteData.onExisting !== 'fail') {
            if (newV === null) {
                $scope.deleteData.onExisting = 'setNone';
            }
            else {
                $scope.deleteData.onExisting = 'replace';
            }
        }
    });
    async function deleter() {
        if (dimension.datatype === 'StoryBinaryLink') {
            await storyRest.del($scope.formData, $scope.deleteData);
        }
        else {
            await KernelClient.run(new DeleteValue(), {
                dimension: dimension,
                value: $scope.formData,
                strategy: $scope.deleteData,
            });
        }
        commit({
            event: 'deleted',
            dimensionValue: $scope.formData,
            dimension: $scope.replacingDimension,
        });
    }
    $scope.showDescriptionTextarea = function () {
        $scope.hasDescription = !$scope.hasDescription;
    };
    $scope.showPlaceholderTextarea = function () {
        $scope.hasPlaceholder = !$scope.hasPlaceholder;
    };
    if (options.mode && options.mode === 'try-delete') {
        $scope.tryDelete();
    }
}
