function Colors() {
    // style attribute used to style elements according to the color (ex: checkmark in color-picker)
    // styles: 'white', 'light', 'colored' (and possibly 'dark' if needed)
    const cardColors = [
        { id: '#ffffff', color: '#ffffff', text: '#424242', style: 'white' },
        { id: '#e0e0e0', color: '#e0e0e0', text: '#424242', style: 'light' },
        { id: '#757575', color: '#757575', text: '#fff', style: 'colored' },
        { id: '#202020', color: '#202020', text: '#fff', style: 'colored' },
        { id: '#006aba', color: '#006aba', text: '#fff', style: 'colored' },
        { id: '#00a2dc', color: '#00a2dc', text: '#fff', style: 'colored' },
        { id: '#4c2a94', color: '#4c2a94', text: '#fff', style: 'colored' },
        { id: '#b7137e', color: '#b7137e', text: '#fff', style: 'colored' },
        { id: '#e01514', color: '#e01514', text: '#fff', style: 'colored' },
        { id: '#f77d00', color: '#f77d00', text: '#fff', style: 'colored' },
        { id: '#ffba00', color: '#ffba00', text: '#fff', style: 'colored' },
        { id: '#a85319', color: '#a85319', text: '#fff', style: 'colored' },
        { id: '#99cc2d', color: '#99cc2d', text: '#fff', style: 'colored' },
        { id: '#00b048', color: '#00b048', text: '#fff', style: 'colored' }, // green
    ];
    const backgroundColors = [
        { id: 'default', style: 'light', textColor: '#424242', purpleTextColor: '#7505f9', class: 'color-default' },
        { id: 'sunset', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-sunset' },
        { id: 'lagoon', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-lagoon' },
        { id: 'miami', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-miami' },
        { id: 'cocktail', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-cocktail' },
        { id: 'greenwich', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-greenwich' },
        { id: 'valencia', style: 'colored', textColor: 'white', purpleTextColor: 'white', class: 'color-valencia' },
    ];
    const backgroundToText = {
        '#e0e0e0': '#424242',
        '#757575': '#424242',
        '#202020': '#202020',
        '#006aba': '#006aba',
        '#00a2dc': '#00a2dc',
        '#4c2a94': '#4c2a94',
        '#b7137e': '#b7137e',
        '#e01514': '#e01514',
        '#f77d00': '#f77d00',
        '#ffba00': '#ffba00',
        '#a85319': '#a85319',
        '#99cc2d': '#99cc2d',
        '#00b048': '#00b048',
    };
    const mostUsed = [
        '#00b048',
        '#f77d00',
        '#ffba00',
        '#00a2dc',
        '#b7137e',
        '#006aba',
        '#e01514',
        '#4c2a94',
        '#99cc2d',
        '#e0e0e0',
        '#202020',
        '#757575',
        '#a85319',
    ];
    return {
        // Returns the list of card colors
        cards: function () {
            return cardColors;
        },
        // Returns the list of most used card colors
        mostUsed: function () {
            return mostUsed;
        },
        newStoryBackgroundColor() {
            return '#e0e0e0';
        },
        newStoryTextColor() {
            return backgroundToText['#e0e0e0'];
        },
        // Returns the color to use for text on top of the card color
        cardTextColor: function (storyColor) {
            const color = cardColors.find((c) => { return c.color == storyColor; });
            return color ? color.text : '#424242';
        },
        cardBorderColor: function (storyColor) {
            const color = cardColors.find((c) => { return c.color == storyColor; });
            if (!color) {
                return '#9e9e9e';
            }
            return color.style == 'colored' ? 'transparent' : '#9e9e9e';
        },
        // Returns the color to use for placeholder text on top of the card color
        cardTextPlaceholderColor: function (storyColor) {
            const color = cardColors.find((c) => { return c.color == storyColor; });
            if (!color) {
                return '#424242';
            }
            return color.style != 'colored' ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.65)';
        },
        // Returns the list of background colors
        backgrounds: function () {
            return backgroundColors;
        },
        // Returns a background info by its name
        backgroundByName: function (name) {
            return backgroundColors.find((back) => {
                return back.id == name;
            });
        },
        // Returns the color to use for text on a given
        // background
        textColor: function (back) {
            return backgroundToText[back] || '#424242';
        },
    };
}
export default Colors();
