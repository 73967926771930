import { Dimension } from '@klaro/corejs/model';
import { Summarizer } from '../../../../../../packages/corejs/src/lang';
angular
    .module('klaro')
    .directive('summariesDropdown', summariesDropdown);
function summariesDropdown(dimensionEditModal) {
    return {
        restrict: 'E',
        require: '^board',
        template: require('@/core/story/summariesDropdown.html'),
        link: function (scope, elm, attrs, board) {
            scope.summarizers = Summarizer.list();
            scope.closeDropdownMenu = function () {
                angular.element(elm).closest('details')[0].removeAttribute('open');
            };
            scope.newNumericDimension = function () {
                const dimensionAdded = (dimension) => {
                    board.addNewDimension(dimension).then(() => {
                        scope.dimensions = board.getDimensions();
                    });
                };
                dimensionEditModal.open(Dimension.newOne('Integer'), board.getDimensions()).result.then(dimensionAdded);
            };
            scope.summarizableDimensions = function () {
                return board.getSummarizableDimensions();
            };
            scope.hasSummary = function (summary, dimension) {
                return board.hasSummary(summary, dimension);
            };
            scope.hasSummaryUsing = function (dimension) {
                return board.hasSummaryUsing(dimension);
            };
            scope.setSummary = function (summary, dimension) {
                board.setSummary(summary, dimension).then(scope.closeDropdownMenu);
            };
        },
    };
}
