import { areDecksCurrentlyOpen } from '@klaro/corejs/state';
angular
    .module('klaro')
    .controller('BoardViewController', BoardViewController);
function BoardViewController($scope, $rootScope, $window, navigation, project, board, boardPermissionsModal, Title, hotkeys, Colors, storySelectionEditModal, boardInviteModal, boardExportModal, imailerService, boardImportModal, infoAndContactModal) {
    $window.scrollTo(0, 0);
    $rootScope.view = 'boards';
    $scope.project = project;
    $scope.board = board;
    // Header
    $scope.gotoBoard = navigation.gotoBoard;
    $scope.refresh = function () {
        return $scope.boardCtrl.refresh();
    };
    $scope.isCurrentBoard = function (board) {
        return board.id === $scope.board.id;
    };
    $scope.supportsDisplayBy = function () {
        return $scope.board.getDisplayMode().supportsDisplayByAnchor;
    };
    $scope.isList = function () {
        return $scope.board.mode === 'list';
    };
    $scope.hasHierarchy = function () {
        return $scope.isList() && $scope.boardCtrl.hasHierarchy();
    };
    $scope.hasBinaryLinkDimension = function () {
        return $scope.board.hasBinaryLinkDimension();
    };
    $scope.isKanban = function () {
        return $scope.board.mode === 'kanban';
    };
    $scope.isGallery = function () {
        return $scope.board.mode === 'gallery';
    };
    $scope.isGantt = function () {
        return $scope.board.mode === 'gantt';
    };
    $scope.isCasino = function () {
        return $scope.board.mode === 'casino';
    };
    $scope.isChart = function () {
        return $scope.board.mode === 'chart';
    };
    $scope.isMatrix = function () {
        return $scope.board.mode === 'matrix';
    };
    $scope.hasColoredDimensions = function () {
        return $scope.boardCtrl.hasColoredDimensions();
    };
    $scope.hasMultipleOrderingDimensions = function () {
        return $scope.board.getDimensionsAt('ordering').length > 1;
    };
    $scope.hasStorySelection = function () {
        return $scope.boardCtrl.hasStorySelection();
    };
    $scope.emptySelection = function () {
        $scope.boardCtrl.emptySelection();
    };
    $scope.selectionSize = function () {
        return $scope.boardCtrl.selectionSize();
    };
    $scope.deleteSelectedStories = function () {
        return $scope.boardCtrl.deleteSelectedStories().catch(navigation.failed);
    };
    $scope.mayArchive = function () {
        return $rootScope.capabilities.cards.actions.archive && $rootScope.ksp.cards.mayArchive($scope.board);
    };
    $scope.toggleArchiveOnSelection = function () {
        return $scope.boardCtrl.toggleArchiveOnSelection().catch(navigation.failed);
    };
    function setArchiveActionLabel() {
        $scope.archiveActionLabel = $scope.boardCtrl.getSelectionXrchiveLabel();
    }
    $scope.$watch('board', setArchiveActionLabel);
    $scope.boardIsDirty = function () {
        return $scope.board.boardIsDirty();
    };
    $scope.toggleBoardSettings = function (tab) {
        $scope.boardCtrl.toggleBoardSettings(tab);
    };
    $scope.setBoardPermissions = function (event) {
        event.preventDefault();
        const modal = boardPermissionsModal.open($scope.board, {});
        modal
            .result
            .then($scope.boardCtrl.handleBoardSettingsResult.bind($scope.boardCtrl));
    };
    $scope.boardInvite = function (event) {
        event.preventDefault();
        boardInviteModal.open($scope.boardCtrl);
    };
    $scope.openSelectionEditModal = function () {
        storySelectionEditModal.open($scope.boardCtrl, {});
    };
    $scope.openExportModal = function (type) {
        boardExportModal.open($scope.boardCtrl, type);
    };
    $scope.openImportModal = function (fileType, importer = null) {
        boardImportModal.open($scope.boardCtrl, fileType, importer);
    };
    // Permissions
    $scope.canReadBoard = function () {
        return $scope.boardCtrl && $scope.boardCtrl.canRead();
    };
    $scope.canWriteToBoard = function () {
        return $scope.boardCtrl && $scope.boardCtrl.canWrite();
    };
    $scope.canManageBoard = function () {
        return $scope.boardCtrl && $scope.boardCtrl.canManage();
    };
    // Decks
    $scope.initLoadHandled = false;
    $scope.$watch('board', (b) => {
        if (!b) {
            return;
        }
        Title.setCurrentPage(b.label);
        if ($scope.initLoadHandled) {
            return;
        }
        imailerService.setBoard(b);
        const hash = navigation.getHash('');
        const match = hash.match(/^settings(-([a-z-]+))?$/);
        if (match) {
            const tab = match[2] || 'display';
            $scope.toggleBoardSettings(tab);
        }
        else if (hash.match(/^\d+/)) {
            $scope.boardCtrl
                .openFullEditStoryModal(parseInt(hash), {
                thenHash: 'clearHash',
            });
        }
        else {
            if (hash.match(/^undefined$/)) { // window reload when new story modal is open
                navigation.clearHash();
            }
            $scope.boardCtrl.handleInfoAndContactModal();
            $scope.boardCtrl.handleBoardObjectiveDisplay();
            $scope.boardCtrl.shouldCreateKindValue({ andSwitch: true });
        }
        $scope.initLoadHandled = true;
    });
    $scope.$watch('board', (newB, oldB) => {
        if (!newB) {
            return;
        }
        if (!$scope.boardBackground || (newB && oldB && newB.background !== oldB.background)) {
            $scope.boardBackground = Colors.backgroundByName(newB.background);
        }
        $scope.areDecksOpen = areDecksCurrentlyOpen($scope.boardCtrl.getBoardState());
    });
    $scope.toggleSelected = function (all) {
        if (all) {
            $scope.boardCtrl.selectAll();
        }
        else {
            $scope.boardCtrl.emptySelection();
        }
    };
    $scope.openInfoAndContact = function () {
        infoAndContactModal.open();
    };
    // Status bar
    hotkeys.bindTo($scope)
        .add({
        combo: 'r',
        description: 'Reset board changes',
        callback: function () { $scope.boardCtrl.resetAllChanges({}); },
    });
    if (board.canManage()) {
        hotkeys.bindTo($scope)
            .add({
            combo: 'b',
            description: 'Toggle board settings',
            callback: function () { $scope.toggleBoardSettings('description'); },
        })
            .add({
            combo: 's',
            description: 'Save board',
            callback: function () { $scope.boardCtrl.saveBoard(); },
        })
            .add({
            combo: 'a',
            description: 'Save as new board',
            callback: function () { $scope.boardCtrl.saveAsNewBoard(); },
        });
    }
    if (board.canWrite()) {
        hotkeys.bindTo($scope)
            .add({
            combo: 'n',
            description: 'New card',
            callback: function () {
                $scope.boardCtrl.openNewStoryModal({});
            },
        })
            .add({
            combo: 'ctrl+a',
            description: 'Select all cards',
            callback: function ($event) {
                $event.preventDefault();
                $scope.boardCtrl.selectAll();
            },
        })
            .add({
            combo: 'esc',
            description: 'Clear selection',
            callback: function ($event) {
                $event.preventDefault();
                $scope.boardCtrl.emptySelection();
            },
        });
    }
}
