import { Dimension } from '../../../model';
export class SaveDimension {
    async run(client, args) {
        const patch = { ...args.patch };
        delete patch.values;
        delete patch.datatype;
        const data = await client.store.connector.dimensions.single(args.id).patch(patch);
        this._result = Dimension.dress(data);
    }
    get result() {
        return this._result;
    }
}
;
