angular
    .module('klaro')
    .directive('storyBigForm', storyBigForm);
function storyBigForm(Colors, robust, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyBigForm.html'),
        scope: {
            inBoard: '=',
            story: '=',
            storyData: '=',
            storyColor: '=',
            dimensions: '=',
            readOnly: '=',
            editing: '=',
            editingDescription: '=',
            savingError: '=',
            onValueCreated: '&',
            onDimensionCreated: '&',
            onReloadRequest: '&',
            onEditingDescriptionRequest: '&',
        },
        link: function (scope, elm, attrs) {
            elm.addClass('story-big-form');
            // Active tab
            scope.activeTab = 'description';
            scope.isActiveTab = function (which) {
                return scope.activeTab === which;
            };
            scope.setActiveTab = function (which) {
                scope.activeTab = which;
            };
            // Archive handling
            scope.isArchived = function () {
                return scope.story && scope.inBoard && scope.story.isArchived(scope.inBoard.getBoardState());
            };
            scope.unarchiveStory = function () {
                scope.inBoard
                    .toggleArchiveStory(scope.story)
                    .then(scope.onReloadRequest.bind(scope))
                    .catch(navigation.failed);
            };
            // Title handling
            const kindDimension = scope.inBoard.getKindDimension();
            scope.$watch(`storyData.${kindDimension.code}`, () => {
                scope.cardPlaceholder = scope.inBoard.getBoardState().getPlaceholderFor(scope.storyData);
            });
            scope.hasTitle = function () {
                const storyData = scope.storyData;
                return storyData.title && storyData.title.trim() !== '';
            };
            // Dimensions handling
            scope.dimensionSearch = { text: '' };
            scope.$watchGroup(['dimensions', 'dimensionSearch.text'], () => {
                let dims = scope.dimensions
                    .filter(d => d.canBeEditedAsCardDimension());
                const search = scope.dimensionSearch.text;
                if (search && search.trim().length > 0) {
                    const rx = new RegExp(scope.dimensionSearch.text, 'im');
                    dims = dims.filter(d => d.dimensionSearchMatchesOn(rx));
                }
                dims = dims.sort((d1, d2) => d1.ordering - d2.ordering);
                scope.editableDimensions = dims;
            });
            scope.isNew = function () {
                return !scope.storyData.id;
            };
            scope.isRequired = function (dim) {
                return dim.required || (scope.inBoard && scope.inBoard.isRequired(dim));
            };
            scope.isRelevant = function (dim) {
                return dim.isRelevant(scope.storyData || {}, scope.inBoard);
            };
            // New dimension flow
            scope.justCreatedDimension = null;
            scope.dimensionAdded = function (dimension) {
                scope.justCreatedDimension = dimension;
                scope.editableDimensions.push(dimension);
                scope.onDimensionCreated({ dimension: dimension });
            };
            scope.isJustCreated = function (dimension) {
                return dimension === scope.justCreatedDimension;
            };
            // color handling
            const coloredDimension = scope.inBoard.getColoredDimension();
            scope.$watch('storyData', () => {
                scope.bigFormCssSelector = `story-big-form-${scope.storyData.identifier || 'undefined'}`;
                if (scope.isNew() || !coloredDimension) {
                    scope.storyColor = Colors.newStoryBackgroundColor();
                    scope.storyTextColor = Colors.newStoryTextColor();
                }
                else {
                    scope.storyColor = scope.inBoard.getStoryColor(scope.storyData);
                    scope.storyTextColor = Colors.cardTextColor(scope.storyColor);
                }
                scope.storyTextPlaceholderColor = `${Colors.cardTextPlaceholderColor(scope.storyColor)} !important`;
            }, true);
            scope.$watch('storyData.identifier', (newVal) => {
                elm.addClass(`story-big-form-${newVal}`);
            });
            scope.getError = function () {
                return robust.message(scope.savingError, false);
            };
        },
    };
}
