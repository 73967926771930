import { v4 } from 'uuid';
export const todolist = async () => {
    return {
        board: {
            id: 'default',
            label: 'Personal todolist',
            mode: 'kanban',
            background: 'default',
            objective: 'Welcome to this to-do list!\n\nThe statuses here are a bit unconventional.\n\nWe often juggle too many tasks and lose track of back-and-forth interactions. That’s why we’ve replaced the usual "To-Do," "Ongoing," and "Done" with a new approach focused on limiting work in progress.\n\nExplore each column\'s purpose, and if it doesn’t suit you, customize it—your work, your process!',
            objectiveModal: true,
            filters: {},
            anchors: {
                displayBy: ['state'],
            },
            decksOpen: true,
            coloredDimension: 'state',
            dimensions: [
                {
                    code: 'state',
                    label: 'Progress',
                    datatype: 'State',
                    userEditable: true,
                    required: true,
                    tagging: true,
                    supportsColor: true,
                    supportsMultiple: false,
                    values: [
                        { id: 'maybe', label: 'Maybe', color: '#757575', description: 'Ideas or tasks to revisit when you have bandwidth.' },
                        { id: 'decided', label: 'Decided', color: '#f77d00', description: 'Approved tasks ready to start.\nKeep it focused!' },
                        { id: 'my-court', label: 'My court', color: '#e01514', description: 'Your responsibility to move forward.\nMax: 3 items.' },
                        { id: 'their-court', label: 'Their court', color: '#4c2a94', description: 'Waiting on others to move forward.\nMax: 3 items.' },
                        { id: 'done', label: 'Done', color: '#00b048' },
                    ],
                }
            ],
            ganttScale: 'month',
            summaries: [
                {
                    code: 'total',
                    label: '# of Cards',
                    expr: 'count',
                    ordering: 10000,
                },
            ],
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
            workspacePermissions: [{
                    workspace: 'admins',
                    canManage: true,
                    canRead: true,
                    canWrite: true,
                }],
            ordering: 1000000,
            createdBy: 'blambeau',
            createdAt: new Date(),
        },
        stories: [
            {
                id: v4(),
                identifier: 1,
                title: 'Start exploring Klaro Cards\nUntil you are sure it\'s the best tool for you',
                state: 'decided',
            }
        ],
    };
};
