import { AbstractCollectionConnector, AbstractSingleResourceConnector } from '../abstract';
export class ApiDimensionsConnector extends AbstractCollectionConnector {
    single(id) {
        return new ApiDimensionConnector(this, id);
    }
    async post(dimensionData) {
        const res = await this.client.api.post(`${this.baseUrl}/dimensions`, dimensionData);
        return res.data;
    }
}
export class ApiDimensionConnector extends AbstractSingleResourceConnector {
    constructor(parent, id) {
        super(parent.client, parent.baseUrl);
        this.parent = parent;
        this.id = id;
    }
    async patch(patch) {
        const res = await this.client.api.patch(`${this.baseUrl}/dimensions/${this.id}`, patch);
        return res.data;
    }
    async createValue(value) {
        const url = `${this.baseUrl}/dimensions/${this.id}/values/`;
        const res = await this.client.api.post(url, value);
        return res.data;
    }
    async saveValue(value) {
        const url = `${this.baseUrl}/dimensions/${this.id}/values/${value.id}`;
        const res = await this.client.api.patch(url, value);
        return res.data;
    }
    async deleteValue(valueId, strategy) {
        const url = `${this.baseUrl}/dimensions/${this.id}/values/${valueId}`;
        await this.client.api.delete(url, { data: strategy });
    }
}
