angular
    .module('klaro')
    .service('auth', auth);
function auth($rootScope, $http, $location, KernelSession, KernelAuth, Patcher) {
    //////////////////////////////////////////////////////////////////
    // About the authentication token
    //
    /** This is the current authorization token to inject on all
     *  backend requests. It defaults to what the kernel has found. */
    let currentToken = null;
    /** This flag captures whether the current token is a special
     *  one installed with `withToken`, typically for join and email
     *  validation tokens that have specific audiences. */
    let tokenSpecial = false;
    /**
     * Returns the current token to inject on backend Api requests
     */
    function getToken() {
        return currentToken;
    }
    /**
     * Returns true when a token is currently installed, false
     * otherwise.
     *
     * Simple boolean abstraction over `getToken()`.
     */
    function isLogged() {
        return !isAnonymous();
    }
    /**
     * Runs `callback` on a specific token, then reinstall the
     * previous one.
     *
     * This method can be used to make backend requests (during
     * callback) with specific tokens, not the main one kept in
     * the service state. This is used for mail-driver processes
     * having audience-specific tokens to use (e.g. join, email
     * validation, etc.)
     *
     * `callback` MUST accept a `done` parameter, a function to
     * call to restore the previous token when process is done.
     */
    function withToken(token, callback) {
        // Save the previous state
        const oldToken = currentToken;
        const oldSpecial = tokenSpecial;
        // Install the special token now
        tokenSpecial = true;
        currentToken = {
            token_type: 'OAuth2',
            access_token: token,
        };
        // Run callback, passing the state restoration procedure
        // to use on done().
        return callback(() => {
            currentToken = oldToken;
            tokenSpecial = oldSpecial;
        });
    }
    /**
     * Returns whether the current token is a special one, installed
     * with `withToken`.
     *
     * This is used, for instance, by the interceptor to know whether
     * it must catch 401 responses and redirect to the login page.
     * (We don't do it on special tokens that may expire and for which
     * we simply want an error message 'Try again' to be displayed).
     */
    function isTokenSpecial() {
        return tokenSpecial;
    }
    //////////////////////////////////////////////////////////////////
    // About the current session and user
    //
    function getSession() {
        return KernelSession.get();
    }
    /**
     * Returns the current user (data object), if any.
     *
     * The actual user is taken from what the Kernel knows, but this
     * method must be used in the application, not the Kernel API, to
     * reduce the kernel surface area in the app.
     */
    function getUser() {
        return getSession().user;
    }
    /**
     * Returns whether the current user is anonymous.
     */
    function isAnonymous() {
        const user = getUser();
        return !user || user.id === undefined;
    }
    /**
     * Returns whether the current user is a user of the current project.
     */
    function isProjectUser() {
        const user = getUser();
        return user && user.isMember;
    }
    /**
     * Returns whether the current user is the project owner.
     */
    function isOwner() {
        const user = getUser();
        return user && !!user.isOwner;
    }
    /**
     * Returns whether the current user is an admin.
     */
    function isAdmin() {
        return getSession().permissions.isAdmin;
    }
    /**
     * Returns wether the current user has email confirmed
     */
    function hasEmailConfirmed() {
        const user = getUser();
        return user && user.emailValidated;
    }
    /**
     * Updates the profile of the current user, using `userData`.
     *
     * - The current user information on the service state is updated
     *   to reflect a success.
     * - The method takes care of actually computing the patch required
     *   by the backend web service.
     *
     * - Failure must be handled by the caller.
     */
    function updateProfile(userData) {
        const original = this.getUser().toRaw();
        const patch = Patcher.shallow(original, userData);
        return $http
            .patch('/api/auth/me', patch)
            .then(async (res) => {
            const user = await KernelAuth.setUser(res.data);
            $emit('klaro.user.changed', {});
            return user;
        });
    }
    /**
     * Refreshes the current user profile.
     */
    function refreshProfile() {
        return $http
            .get('/api/auth/me')
            .then((res) => {
            const user = KernelAuth.setUser(res.data);
            $emit('klaro.user.changed', {});
            return user;
        });
    }
    //////////////////////////////////////////////////////////////////
    // About the login procedure, logout and forbidden access
    //
    function toLocationWithContext(newLocation, withContext = false) {
        const path = $location.path();
        const hash = $location.hash();
        const redirectTo = hash ? `${path}#${hash}` : path;
        // If the user is already on the login page, we do not
        // redirect them to the login page
        if (path === '/auth/login') {
            return;
        }
        if (withContext && path !== newLocation) {
            $location.path(newLocation).search({
                redirectTo,
            });
        }
        else {
            $location.path(newLocation);
        }
    }
    /**
     * Initiate the login flow while keeping track of the original context
     * in order to restore the user's original intent after a successful
     * authentication
     */
    function loginPage(keepContext = false) {
        toLocationWithContext('/auth/login', keepContext);
    }
    function forbiddenPage(keepContext = false) {
        toLocationWithContext('/auth/forbidden', keepContext);
    }
    //////////////////////////////////////////////////////////////////
    // Email validation process
    /**
     * Asks the backend for a validation email to be sent to the current
     * user.
     *
     * - Failure must be handled by the caller.
     */
    function requestEmailValidation() {
        return $http
            .post('/api/auth/validate/request/');
    }
    /**
     * Asks the backend to validate the user email, using the special token
     * passed as parameter (and normally coming from the email itself)
     *
     * - Failure must be handled by the caller.
     */
    function validateEmail(token) {
        return this
            .withToken(token, (done) => {
            return $http
                .post('/api/auth/validate/callback/')
                .then(done);
        })
            .then(refreshProfile);
    }
    function initiateEmailChange(formData) {
        return $http
            .post('/api/auth/email/change/request/', formData);
    }
    function validateEmailChange(token) {
        return this
            .withToken(token, (done) => {
            return $http
                .post('/api/auth/email/change/callback/')
                .then(done);
        })
            .then(refreshProfile);
    }
    /**
     * Asks the backend for a short lived token, typically used to
     * login another device on the same account
     *
     * Must be provided with the user password for security purposes
     */
    function getQuickLoginToken(password) {
        const { email } = getUser();
        return $http.post('/api/auth/tokens/', {
            grant_type: 'quick_login',
            client_id: email,
            client_secret: password,
        }).then(response => {
            return response.data.access_token;
        }).catch((response) => {
            if (response.data && response.data.description) {
                throw response.data.description;
            }
            throw response.data;
        });
    }
    //////////////////////////////////////////////////////////////////
    // Listeners
    function $on(to, args) {
        return $rootScope.$on(to, args);
    }
    function $emit(to, args) {
        return $rootScope.$emit(to, args);
    }
    $on('klaro.application.reload', refreshProfile);
    //////////////////////////////////////////////////////////////////
    // View as handling
    function getViewAs() {
        return getSession().viewAs;
    }
    async function setViewAs(workspace) {
        if (workspace === undefined) {
            return;
        }
        if (typeof (workspace) === 'string') {
            workspace = { code: workspace };
        }
        const current = getViewAs();
        if (!current || current.code !== workspace.code) {
            const user = getUser();
            const checkWorkspace = user.workspaces.find(t => t.code === workspace.code);
            if (checkWorkspace) {
                await KernelAuth.setViewAs(checkWorkspace);
                $emit('klaro.viewAs.changed', checkWorkspace);
            }
            else {
                gotoHome();
            }
        }
    }
    function findSuitableWorkspace(workspaces) {
        const user = getUser();
        const suitableWorkspace = user.workspaces.find((w) => {
            if (workspaces.indexOf(w.code) > -1) {
                return w;
            }
        });
        return suitableWorkspace;
    }
    function resetViewAs() {
        KernelAuth.resetViewAs();
    }
    //////////////////////////////////////////////////////////////////
    // Initialization process
    /**
     * Simple methods that goes to the home page
     */
    function gotoHome() {
        $location.path('/');
    }
    // Service description
    return {
        getSession: getSession,
        gotoHome: gotoHome,
        updateProfile: updateProfile,
        refreshProfile: refreshProfile,
        requestEmailValidation: requestEmailValidation,
        validateEmail: validateEmail,
        initiateEmailChange: initiateEmailChange,
        validateEmailChange: validateEmailChange,
        loginPage: loginPage,
        forbiddenPage: forbiddenPage,
        isLogged: isLogged,
        getToken: getToken,
        getQuickLoginToken: getQuickLoginToken,
        getUser: getUser,
        isOwner: isOwner,
        isAdmin: isAdmin,
        isAnonymous: isAnonymous,
        isProjectUser: isProjectUser,
        hasEmailConfirmed: hasEmailConfirmed,
        withToken: withToken,
        isTokenSpecial: isTokenSpecial,
        $on: $on,
        getViewAs: getViewAs,
        setViewAs: setViewAs,
        resetViewAs: resetViewAs,
        findSuitableWorkspace: findSuitableWorkspace,
    };
}
