import { ArrayUtils, Patcher } from '../utils';
// TODO: fix typescript
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const identity = function (x) {
    return x;
};
const byId = function (x) {
    if (x.id === null || x.id === undefined) {
        // The [none] value uses null as identifier. We need to keep it exactly
        // (not the same as the empty string).
        // We do the same with undefined for completeness.
        return x.id;
    }
    else {
        // Otherwise, we compare value id as strings. Indeed, we let dimensions
        // use Integers (looking at you StoryBinaryLink) both on stories and for
        // dimension value ids BUT the filters are passed through the URL
        // query string so those integers are not always preserved in practice...
        return `${x.id}`;
    }
};
/**
 * This class implements the filtering expressions captured by Klaro decks
 * and similar features in board settings.
 *
 * Those filtering expressions are Conjunctive Normal Forms over sets of
 * dimensions and their values. E.g.
 *
 *     (kind = epic OR kind = story) AND (state = todo)
 *
 * This class internally represents those expressions through a javascript
 * object having dimension codes as keys and arrays of dimension values as
 * values. E.g.
 *
 *     { kind: [ epic, story ], state: [ todo ] }
 *
 * However, the semantics of values is kept opaque for the class. Used values
 * can be simple scalars (e.g. "epic") or complex dimension values such as
 * `{ id: "epic", label: "Epic" }`, provided that the usage of the class's
 * axiomatic API (`with`, `without`, `add`, etc.) is consistent with respect to
 * the value representation.
 *
 * Since javascript does not follow a data semantics for '==' on objets, it is
 * however necessary to help the class comparing the values used in practice.
 * This is implemented through an `extractor` function that MUST meet the
 * following invariant:
 *
 *     (extractor(v1) == extractor(v2)) == true iif v1 == v2
 *
 * In other words, the extractor is such that value comparisons can actually be
 * applied on javascript scalars *representing* the values (also called value
 * representators) instead of applying comparisons on the values themselves. The
 * extractor can be specified at construction or through the world argument passed
 * to information contracts. For real Klaro dimension values, a typical extractor
 * function is:
 *
 *     let byId = (x) => return x.id
 *
 * This class exposes a main information contract called `cnf` that corresponds
 * to the data representation shown above, e.g. `{ state: [ todo ] }`. The world
 * argument can be used to convert dimension value representators (scalar) to
 * complex values (non scalar) and vice-versa, through a `dimensions` array and a
 * corresponding `extractor` function. In such case `dimensions` is used at dressing
 * time (to find the complex value corresponding to a representor), and `extractor`
 * at undressing time (to convert the value back to a scalar). This makes the `cnf`
 * data representation fairly easy to use in URLs and other pure string persistence
 * mechanisms. Please see `cnf` for details.
 */
class Filters {
    constructor(raw = {}, extractor = identity) {
        this.__raw = Object.assign({}, raw);
        this.__extractor = extractor;
    }
    /**
     * Invokes information contracts according to the `raw` data.
     *
     * See `cnf` for details, the only information contract so far.
     */
    static dress(raw, world = undefined, keepEmpty = false) {
        if (raw.constructor && raw.constructor === this) {
            return raw;
        }
        world = world ? world : {};
        world.extractor = world.extractor || (world.dimensions ? byId : identity);
        return Filters.cnf(raw, world, keepEmpty);
    }
    /*
     * Dresses `raw` from a CNF data representation to a Filters instance.
     *
     * The `raw` param must be a valid CNF data representation meeting the
     * { ...: String|[String] } Finitio schema.
     *
     * Examples:
     *
     *     Filters.cnf({ state: "todo" }) => `state = todo`
     *     Filters.cnf({ state: ["todo", "done"] }) => `(state = todo OR state = done)`
     *     Filters.cnf({ state: "todo", assignee: "blambeau"] }) => `state = todo AND assignee = done`
     *
     * This information contract works hand in hand with `toCnf` on instances.
     *
     * The world can be used to pass an `extractor` and `dimensions` to convert from
     * the data realm to the javascript realm. This mecanism can be used to have scalar
     * values on the data representation, but dimension values when dressed.
     *
     * Examples:
     *
     *     # When not using the extractor mecanism, values are real scalar on the
     *     # entire API...
     *     let state = new Dimension({ code: "state", values: ["todo", "done"] })
     *     let f = Filters.cnf({ state: "todo" })
     *     f.isFilteredBy(state, "todo") == true
     *     f.isFilteredBy(state, "done") == false
     *     f.toCnf() == { state: ["todo", "done"] }
     *
     *     # When complex dimension values are used, the extractor can be used
     *     # to convert from a data realm to a js realm
     *     let todo = { id: "todo" }
     *     let done = { id: "done" }
     *     let state = new Dimension({ code: "state", values: [todo, done] })
     *     let f = Filters.cnf({ state: "todo" }, { extractor: byId, dimensions: [state] })
     *     f.isFilteredBy(state, todo) == true
     *     f.isFilteredBy(state, done) == false
     *     f.toCnf() == { state: ["todo", "done"] }
     *
     */
    static cnf(raw, world = undefined, keepEmpty = false) {
        if (!world) {
            world = {};
        }
        const extractor = (world.extractor || identity);
        const dnfs = Object.keys(raw).reduce((acc, k) => {
            // By default, the DNF is simply the array associated to `k`...
            let dnf = ArrayUtils.dress(raw[k]);
            // Now, normalize keys, to remove trailing [] coming from multi-valued params
            k = k.replace(/\[\]$/, '');
            if (k.indexOf('_') !== 0) { // special arguments starting with '_' (such as `_q`, `_pageSize`, '...') are not dimensions
                // ... but when dimensions are specified, we need to convert to complex values
                if (world.dimensions) {
                    const dimensions = world.dimensions;
                    // let first find the dimension
                    const dim = dimensions.find((dim) => dim.code === k);
                    if (dim) {
                        // convert values through a functional reduction, ignored
                        // keys mapping to no value at all according to the extractor
                        dnf = dnf.reduce((acc2, v) => {
                            const val = dim.values.find((dv) => extractor(dv) == v);
                            return val === undefined ? acc2 : acc2.concat([val]);
                        }, []);
                    }
                    else {
                        // Dimension has not been found, let ignore that key `k`
                        // (since code below will not assign empty arrays to acc[k])
                        dnf = [];
                    }
                }
                // Only keep non-empty DNFs
                if (dnf.length > 0 || keepEmpty) {
                    acc[k] = dnf;
                }
            }
            return acc;
        }, {});
        return new Filters(dnfs, world.extractor);
    }
    /**
     * Returns the CNF data representation, converting complex dimension values
     * to scalars using the extractor.
     */
    toCnf(keepEmpty = false) {
        const cnf = {};
        for (const k in this.__raw) {
            if (this.__raw[k].length === 0 && !keepEmpty) {
                continue;
            }
            cnf[k] = (k === '_q') ? this.__raw[k] : this.__raw[k].map(v => this.__extractor(v));
        }
        return cnf;
    }
    /**
     * Returns an object that can be used as query params variant of the CNF,
     * i.e. where multi-valued keys are post-fixed with [].
     */
    toQueryParams() {
        const cnf = this.toCnf(true);
        return Object.keys(cnf).reduce((h, k) => {
            const vs = cnf[k];
            h[vs.length === 1 ? k : `${k}[]`] = (vs.length === 0 ? [''] : vs);
            return h;
        }, {});
    }
    /**
     * Alias for `toCnf()`, used by Klaro UI to send data to the backend. Please
     * do not use anywhere else, and favor `toCnf` instead.
     */
    toRaw() {
        return this.toCnf();
    }
    isEmpty() {
        return Object.keys(this.__raw).length === 0;
    }
    size() {
        return Object.keys(this.__raw).length;
    }
    /** Returns the value filtering a particular dimension. */
    getAlong(dimension) {
        return this.__raw[dimension.code];
    }
    /** Installs a dimension => value filter.
     *  Replaces all existing filters in the same dimension. */
    set(dimension, value) {
        const add = {};
        add[dimension.code] = ArrayUtils.dress(value);
        const raw = Object.assign({}, this.__raw, add);
        return new Filters(raw, this.__extractor);
    }
    /** Installs a dimension => value filter.
     *  If there is already a distinct value for this dimension,
     *  adds the new value as an alternative (union) for the same filter. */
    add(dimension, value) {
        if (this.isFilteredBy(dimension, value)) {
            return this;
        }
        const clone = Object.assign({}, this.__raw);
        const old = ArrayUtils.dress(clone[dimension.code]);
        clone[dimension.code] = ArrayUtils.union(old, [value]);
        return new Filters(clone, this.__extractor);
    }
    /** Removes every filter along a particular dimension. */
    removeAll(dimension) {
        const clone = Object.assign({}, this.__raw);
        delete clone[dimension.code];
        return new Filters(clone, this.__extractor);
    }
    /** Removes the indicated filter along a particular dimension. */
    remove(dimension, value) {
        const clone = Object.assign({}, this.__raw);
        if (dimension.code === '_q') {
            delete clone._q;
        }
        else {
            const old = ArrayUtils.dress(clone[dimension.code]);
            const lookedAt = this.__extractor(value);
            clone[dimension.code] = ArrayUtils.without(old, undefined, (x) => {
                return this.__extractor(x) === lookedAt;
            });
        }
        return new Filters(clone, this.__extractor);
    }
    /** Toggle filtering of a dimension => value. If that value was
      set, it is removed, otherwise it is set. */
    toggle(dimension, value) {
        if (this.isFilteredBy(dimension, value)) {
            return this.remove(dimension, value);
        }
        else {
            return this.add(dimension, value);
        }
    }
    /** Returns the dimension values applied as filter */
    getFilter(dimension) {
        const filter = this.__raw[dimension.code];
        return filter || [];
    }
    /** Returns whether a dimension is currently filtered. */
    hasFilter(dimension) {
        const filter = this.getFilter(dimension);
        return Array.isArray(filter) && filter.length > 0;
    }
    /** Alias for isFilteredBy.
      * Needed because a Filters instance can be used
      * as context in Dimension.withoutDeprecatedValues(context).
      */
    isUsingValueOfDimension(value, dimension) {
        return this.isFilteredBy(dimension, value);
    }
    /** Returns whether a dimension is currently filtered by a particular value. */
    isFilteredBy(dimension, value) {
        if (this.hasFilter(dimension)) {
            const dimValues = this.__raw[dimension.code];
            const v2 = this.__extractor(value);
            return dimValues.some(v => this.__extractor(v) === v2);
        }
        else {
            return false;
        }
    }
    /** Merges with the filters, overriding existing entries by others'. */
    merge(others) {
        return new Filters(Object.assign({}, this.__raw, others.__raw), this.__extractor);
    }
    /** Computes the difference of thes filters with others. The result is
     * a Filters instance having only the CNF entries that are different on
     * this than on others. */
    diff(other) {
        const diff = Patcher.shallow(other.__raw, this.__raw, []);
        return new Filters(diff, this.__extractor);
    }
    /** Removes all dimensions filtered. */
    clear() {
        return new Filters({}, this.__extractor);
    }
    /** Returns true if this filters accept the story as an included one,
     *  false otherwise. */
    accept(story, dimensions, globalContext) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return dimensions.every((d) => {
            const dimValues = self.__raw[d.code];
            if (Array.isArray(dimValues) && dimValues.length > 0) {
                return dimValues.some(v => story.hasValue(d, v, globalContext));
            }
            else {
                return true;
            }
        });
    }
    equiv(other) {
        if (other === this) {
            return true;
        }
        const mine = this.toCnf();
        const myKeys = Object.keys(mine);
        const your = other.toCnf();
        const yourKeys = Object.keys(your);
        return ArrayUtils.isSameset(myKeys, yourKeys) && myKeys.every((k) => {
            return ArrayUtils.isSameset(mine[k], your[k]);
        });
    }
    implies(other, _dimensions) {
        const mine = this.toCnf();
        const your = other.toCnf();
        // Two conditions:
        // 1. other may not have additional clauses
        //      (X ^ Y) =/> (X' ^ Y' ^ ...)
        // 2. for every X & X' disjunctions, X must imply X'
        //    which means that X must be a subset of X'
        //      (x1 v x2) => (x'1 v x'2 v x'3)
        return Object.keys(your).every((k) => !!mine[k]) && Object.keys(mine).every((k) => {
            return (your[k] === undefined) || ArrayUtils.isSubset(mine[k], your[k]);
        });
    }
    /** Projects these filters, keeping only dimensions specified. */
    project(dimensions) {
        const myCnf = this.toCnf();
        const prCnf = dimensions.reduce((fs, dim) => {
            const mine = myCnf[dim.code];
            if (mine) {
                fs[dim.code] = mine;
            }
            return fs;
        }, {});
        return new Filters(prCnf, this.__extractor);
    }
    /** Projects these filters, keeping none of the dimensions specified. */
    allbut(dimensions) {
        const clone = Object.assign({}, this.__raw);
        dimensions.forEach((dim) => {
            delete clone[dim.code];
        });
        return new Filters(clone, this.__extractor);
    }
    withDimensionRenamed(oldCode, newCode) {
        const clone = Object.assign({}, this.__raw);
        if (clone[oldCode]) {
            clone[newCode] = clone[oldCode];
            delete clone[oldCode];
        }
        return new Filters(clone, this.__extractor);
    }
    withDimensionRemoved(code) {
        const clone = Object.assign({}, this.__raw);
        delete clone[code];
        return new Filters(clone, this.__extractor);
    }
}
export default Filters;
