import { Markdowner } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('quickTipModal', quickTipModal)
    .controller('QuickTipModalController', QuickTipModalController);
function quickTipModal(klaroModal) {
    function open(options) {
        return klaroModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/support/quickTipModal.html'),
            controller: 'QuickTipModalController',
            windowClass: 'klaro-modal quick-tip-modal',
            size: 'small',
            resolve: {
                options: options,
            },
        });
    }
    return { open: open };
}
function QuickTipModalController($scope, $uibModalInstance, options, shakeIt) {
    var _a;
    $scope.options = options;
    (_a = $scope.options).title || (_a.title = 'Quick tip');
    options.messageHtml || (options.messageHtml = Markdowner.light.render(options.message));
    options.hints.forEach(h => h.textHtml || (h.textHtml = Markdowner.light.render(h.text)));
    $scope.close = function ($event) {
        $uibModalInstance.dismiss();
        if (options.shakeItOnClose) {
            shakeIt($event, options.shakeItOnClose);
        }
    };
}
