import { Summary } from '../../../model';
export class LocalSummariesConnector {
    constructor(connector, client, data) {
        this.connector = connector;
        this.client = client;
        this.data = data;
    }
    async get() {
        return Promise.resolve(this.client.store.board?.board.summaries || [
            Summary.dress({
                code: 'total',
                label: 'Count cards',
                expr: 'count',
                ordering: 10000,
            })
        ]);
    }
}
