angular
    .module('klaro')
    .directive('markdownWidget', markdownWidget);
function markdownWidget(Markdowner, $sce) {
    return {
        scope: {
            ngModel: '=',
            editable: '=',
            editing: '=',
            onSaveRequest: '&',
            onCloseRequest: '&',
        },
        template: require('@/support/markdownWidget.html'),
        link: function (scope, element, attributes) {
            const textElm = element.find('.text');
            scope.startEdit = function ($event) {
                if (!scope.editable) {
                    return;
                }
                if ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }
                scope.editing = true;
            };
            scope.stopEdit = function ($event) {
                if ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                }
                scope.editing = false;
            };
            scope.toggleEdit = function ($event) {
                if (scope.editing) {
                    scope.stopEdit($event);
                }
                else {
                    scope.startEdit($event);
                }
            };
            scope.hasText = function () {
                return scope.ngModel && scope.ngModel.trim() != '';
            };
            function onDblClick() {
                if (scope.editing) {
                    return;
                }
                scope.$apply(() => scope.editing = true);
            }
            element[0].addEventListener('dblclick', onDblClick);
            scope.$watch('ngModel', (newVal) => {
                let html;
                if (!newVal) {
                    return;
                }
                html = Markdowner.full.render(newVal);
                html = $sce.trustAsHtml(html);
                textElm[0].innerHTML = html;
                textElm.find('li.task-list-item input').on('change', (event) => {
                    scope.$apply(() => {
                        const taskId = event.target.dataset.text;
                        scope.ngModel = Markdowner.toggleTask(scope.ngModel, taskId);
                    });
                });
            });
            scope.helpShown = false;
            scope.toggleHelp = function () {
                scope.helpShown = !scope.helpShown;
            };
        },
    };
}
