import { Boot, createKlaroStore, KlaroClient, KlaroStoreProvider, LoadMe, LoadMyBoards, LoadMyProjects, MemoryStore, SwitchProject, } from '@klaro/corejs/client';
angular
    .module('klaro.kernel')
    .constant('KernelConstants', {})
    .service('KernelClient', KernelClient);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelClient($log, $window, KernelConstants, $rootScope, $browser) {
    class Client {
        async init() {
            $log.info('Booting the Klaro Client...');
            const loc = $window.location;
            const apiBaseURL = `${loc.protocol}//${loc.host}/api/`;
            const subdomain = loc.host.split('.')[0];
            const storeProvider = new KlaroStoreProvider(() => KernelConstants.store, (newStore) => { KernelConstants.store = newStore; });
            KernelConstants.client = new KlaroClient(storeProvider)
                .withPreferencesStore(new MemoryStore())
                .withDefaultEventually();
            KernelConstants.store = createKlaroStore(KernelConstants.client, {
                apiOptions: {
                    baseURL: apiBaseURL,
                    subdomain: subdomain,
                },
            });
            KernelConstants.client.init();
            this.installEvents(KernelConstants.client, subdomain);
            await KernelConstants.client.boot();
            return Promise.resolve(KernelConstants.client);
        }
        get instance() {
            return KernelConstants.client;
        }
        get api() {
            return this.instance.api;
        }
        get store() {
            return this.instance.store;
        }
        get capabilities() {
            return this.instance.store.connector.capabilities;
        }
        get eventually() {
            return this.instance.eventually;
        }
        run(op, args) {
            const promise = this.instance.run(op, args);
            // whatever what happens: success or error
            promise.finally(() => {
                // we want to launch a digest cycle after the operation
                // is ran. But we also want to be the very last to pass
                // while promise await/then could still be attached.
                // Hence the $browser.defer
                $browser.defer(() => $rootScope.$apply());
            });
            return promise;
        }
        subscribe(op, callback) {
            return this.instance.subscribe(op, callback);
        }
        installEvents(client, subdomain) {
            client.subscribe(Boot, async (_op, _args) => {
                // any error at this stage will be raised up to v1/boot.js
                // which will catched and managed there
                await client.run(new LoadMyProjects);
                await client.run(new SwitchProject, { subdomain });
            });
            client.subscribe(SwitchProject, async (_op, _args) => {
                try {
                    await client.run(new LoadMe);
                    await client.run(new LoadMyBoards);
                }
                catch (ex) {
                    // We can safely ignore this. User is not or no longer logged,
                    // which is fine.
                }
            });
        }
    }
    return new Client();
}
