angular
    .module('klaro')
    .service('boardObjectiveModal', boardObjectiveModal)
    .controller('BoardObjectiveController', BoardObjectiveController);
function boardObjectiveModal(klaroModal) {
    return {
        open: function (boardCtrl) {
            const seemsBig = boardCtrl.getBoardState().hasLongObjective();
            const size = seemsBig ? 'medium' : 'small';
            return klaroModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardObjectiveModal.html'),
                controller: 'BoardObjectiveController',
                windowClass: 'klaro-modal board-objective-modal',
                size: `${size} full-mobile`,
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                },
            });
        },
    };
}
function BoardObjectiveController($scope, boardCtrl, $uibModalInstance, MarkdownIt, shakeIt, $rootScope) {
    const board = boardCtrl.getBoardState();
    $scope.boardLabel = function () {
        return board.label;
    };
    $scope.boardObjective = MarkdownIt.render(board.objective);
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
    $scope.options = { displayNextTime: false };
    $scope.submit = function ($event) {
        if ($scope.options.displayNextTime) {
            boardCtrl.markObjectiveUnshown();
        }
        else {
            boardCtrl.markObjectiveShown();
        }
        $uibModalInstance.close();
        if ($rootScope.isPlayground) {
            shakeIt($event, 'info-and-contact-button', 'animate-shake-xs');
        }
    };
}
