import { Colors } from '../../utils';
import { UserDefined } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
import { toId, toLabel } from './types';
export const MAX_LENGTH = 30;
export class UserDefinedCandidate extends DimensionCandidate {
    _deliver() {
        const nonNulls = [...this.values.keys()].filter(Boolean);
        const values = new Set([...nonNulls]);
        const mostUsedColors = Colors.mostUsed();
        const colors = values.size <= mostUsedColors.length ? mostUsedColors : ['#ffffff'];
        return [UserDefined, {
                code: this.dimName,
                datatype: 'UserDefined',
                values: [...values].map((v, i) => ({
                    id: toId(v),
                    label: toLabel(v),
                    semantics: null,
                    color: colors[i % colors.length],
                    ordering: (1 + i) * 1000000,
                    deprecated: false
                })),
            }];
    }
    placeBet() {
        const nonNulls = [...this.values.keys()].filter(Boolean);
        let tooLong = 0.0;
        for (const value of nonNulls) {
            // Stop early when faced with non strings
            if (typeof value !== 'string') {
                return 0.0;
            }
            if (value.length > MAX_LENGTH) {
                tooLong += 1.0;
            }
        }
        return 1.0 - (tooLong / this.valuesSeen);
    }
}
