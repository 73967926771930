import { Dimension } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('dimensionRest', dimensionRest);
function dimensionRest($http) {
    const dress = Dimension.dress;
    return {
        list: list,
        getOne: getOne,
        del: del,
        ensure: ensure,
        enable: enable,
        patch: patch,
        //
        saveValue: saveValue,
        patchValue: patchValue,
        orderAlphabetically: orderAlphabetically,
    };
    function list() {
        return $http
            .get('/api/dimensions/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function getOne(dimension) {
        return $http
            .get(`/api/dimensions/${dimension.id}`)
            .then((response) => {
            return dress(response.data);
        });
    }
    function del(dimension) {
        const url = `/api/dimensions/${dimension.id}`;
        return $http
            .delete(url);
    }
    function enable(dimension) {
        return $http
            .patch(`/api/dimensions/${dimension.id}/enable`);
    }
    function ensure(datatype) {
        const url = `/api/dimensions/installers/${datatype}`;
        return $http
            .post(url, {})
            .then((res) => {
            return dress(res.data);
        });
    }
    function patch(dimension, patch) {
        const url = `/api/dimensions/${dimension.id}`;
        return $http
            .patch(url, patch)
            .then((res) => {
            return dress(res.data);
        });
    }
    ///
    function saveValue(dimension, value) {
        if (value.id) {
            const url = `/api/dimensions/${dimension.id}/values/${value.id}`;
            const tosend = angular.extend({}, value);
            return $http
                .patch(url, tosend)
                .then((res) => {
                return res.data;
            });
        }
        else {
            return createValue(dimension, value);
        }
    }
    function createValue(dimension, value) {
        const url = `/api/dimensions/${dimension.id}/values/`;
        const tosend = angular.extend({}, value);
        if (tosend.ordering === undefined) {
            tosend.ordering = 1000000 + dimension.values.reduce((ordering, x) => {
                return ordering > x.ordering ? ordering : x.ordering;
            }, 0);
        }
        return $http
            .post(url, tosend)
            .then((res) => {
            return res.data;
        });
    }
    function patchValue(dimension, value, patch) {
        const url = `/api/dimensions/${dimension.code}/values/${value.id}`;
        return $http
            .patch(url, patch)
            .then((res) => {
            return res.data;
        });
    }
    function exists(x) {
        return x !== null && x !== undefined;
    }
    function orderAlphabetically(dimension) {
        return $http
            .post(`/api/dimensions/${dimension.id}/values/actions/order-alphabetically`)
            .then((res) => {
            return res.data;
        });
    }
}
