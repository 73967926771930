angular
    .module('klaro')
    .directive('noMobileNotice', noMobileNotice);
function noMobileNotice($window) {
    return {
        template: require('@/support/noMobileNotice.html'),
        scope: true,
        link: function (scope, element, attributes) {
            scope.isCampaign = $window.location.pathname.match(/exemples/);
        },
    };
}
