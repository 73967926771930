import { v4 } from 'uuid';
import { ApiStoriesConnector } from '../api/ApiStoriesConnector';
export class LocalStoriesConnector extends ApiStoriesConnector {
    constructor(connector, client, data, baseUrl) {
        super(client, baseUrl);
        this.connector = connector;
        this.client = client;
        this.data = data;
    }
    async get(params) {
        const stories = await this.data.stories.values();
        if (stories && stories.length) {
            return stories;
        }
        else if (this.connector.feedStrategy === 'api') {
            const stories = await super.get(params);
            await this.data.stories.addOriginals(stories);
            return this.data.stories.values();
        }
        else {
            return [];
        }
    }
    async delete(params) {
        await params.stories.reduce((p, s) => {
            return p.then(() => this.data.stories.delete(s.id));
        }, Promise.resolve());
    }
    async patch(patch) {
        return await this.data.stories.store(patch.patch);
    }
    async post(story) {
        return this.create([story]);
    }
    async postMany(stories) {
        const existing = await this.data.stories.values();
        const largestId = existing.reduce((max, s) => {
            const id = s.identifier || 0;
            return max > id ? max : id;
        }, 1);
        const toCreate = stories.map((s, i) => {
            s.id = s.id || v4();
            s.identifier || (s.identifier = s.identifier || 1 + i + largestId);
            return s;
        });
        await this.data.stories.store(toCreate);
        return toCreate;
    }
    async create(stories) {
        const saved = await this.postMany(stories);
        return saved[0];
    }
    single(id) {
        const parentSingle = super.single(id);
        const data = this.data;
        return {
            cover: {
                async set() {
                    throw new Error('LocalStoriesCoverConnector not implemented.');
                },
                async unset() {
                    throw new Error('LocalStoriesCoverConnector not implemented.');
                }
            },
            async get(params) {
                const fullStory = await parentSingle.get(params);
                data.stories.addOriginals([fullStory]);
                return data.stories.get(fullStory.id);
            },
            async patch(patch) {
                await data.stories.store([patch]);
                return data.stories.get(patch.id);
            },
            delete(_params) {
                throw new Error('LocalStoriesConnector.single#delete() not implemented.');
            }
        };
    }
    search(_filters) {
        throw new Error('LocalStoriesConnector#search() not implemented.');
    }
}
