import { ApiDimensionConnector, ApiDimensionsConnector } from '../api/ApiDimensionsConnector';
import { PatchableStorage } from './PatchableStorage';
import { MemoryPatchStorage } from './MemoryPatchStorage';
import { v4 } from 'uuid';
import { toCode } from '../../../utils';
import { KlaroError } from '../../errors';
export class LocalDimensionsConnector extends ApiDimensionsConnector {
    constructor(connector, client, data, baseUrl) {
        super(client, baseUrl);
        this.connector = connector;
        this.client = client;
        this.data = data;
    }
    single(id) {
        return new LocalDimensionConnector(this, id);
    }
    async post(dimension) {
        return this.create([dimension]);
    }
    async create(dimensions) {
        const installers = await this.client.store.installers;
        const existing = await this.data.dimensions.values();
        const toCreate = dimensions.map((d) => {
            const installer = installers?.find(i => i.datatype === d.datatype);
            d.id = d.id || v4();
            d.code = toCode(d.label, existing.map(d => d.code));
            d.ordering = existing.length * 1000000;
            d.deleted = false;
            d.deletedAt = null;
            d.deletedBy = null;
            d.description = '';
            d.supportsColor = true; // TODO
            d.semanticsType = installer?.semanticsType || null;
            d.relevantKinds = [];
            d.attribute = false; // TODO
            d.supportsMultiple = !!installer?.supportsMultiple; // TODO
            d.supportsRequired = !!installer?.supportsRequired;
            d.supportsTagging = !!installer?.supportsTagging;
            d.supportsAlphabeticalOrdering = false; // TODO
            d.supportsColor = true; // TODO
            d.userEditable = true; // TODO
            d.values = d.values.map((v, i) => {
                return {
                    ...v,
                    id: toCode(v.label),
                    ordering: 100000 * i,
                    deprecated: false,
                };
            });
            d.values.unshift({
                id: null,
                label: 'No value',
                semantics: null,
                color: '#ffffff',
                ordering: 0,
                deprecated: false,
            });
            return d;
        });
        const saved = await this.data.dimensions.store(toCreate);
        return saved[0];
    }
}
export class LocalDimensionConnector extends ApiDimensionConnector {
    constructor(parent, id) {
        super(parent, id);
        this.parent = parent;
        this.id = id;
    }
    async patch(patch) {
        const data = this.parent.data;
        const oldDim = await data.dimensions.get(this.id);
        const newDim = { ...oldDim, ...patch };
        await data.dimensions.store([newDim]);
        return data.dimensions.get(this.id);
    }
    async delete(_params) {
        const data = this.parent.data;
        await data.dimensions.delete(this.id);
    }
    async saveValue(value) {
        var _a, _b;
        const newValue = {
            id: value.id || value.label,
            ...value
        };
        const oldDim = this.parent.client.store.board?.board.dimensionById(this.id);
        const newDim = oldDim?.addValue(newValue);
        const data = this.parent.data;
        (_a = data.dimensionValues)[_b = this.id] || (_a[_b] = new PatchableStorage(new Map(), new MemoryPatchStorage()));
        await data.dimensionValues[this.id].store([newValue]);
        await data.dimensions.store([newDim.toRaw()]);
        const oldValue = oldDim?.getValueById(value.id) || {};
        return { ...oldValue, ...newValue };
    }
    async createValue(value) {
        return this.saveValue(value);
    }
    async deleteValue(valueId, strategy) {
        const data = this.parent.data;
        const oldDim = this.parent.client.store.board?.board.dimensionById(this.id);
        if (!oldDim)
            return;
        const value = oldDim.getValueById(valueId);
        if (!value)
            return;
        const stories = await data.stories.values();
        const isUsed = stories.some(s => oldDim.storyValueMatches(s, value, null));
        if (isUsed) {
            if (strategy.onExisting === 'fail') {
                throw new KlaroError('Value is currently used');
            }
            else if (strategy.onExisting === 'setNone') {
                const reworked = stories.map(s => {
                    return {
                        id: s.id,
                        ...oldDim.getPatchForStoryValueTo({ id: null })
                    };
                });
                await data.stories.store(reworked);
            }
            else {
                const newValue = oldDim.getValueById(strategy.replaceBy);
                const reworked = stories.map(s => {
                    return {
                        id: s.id,
                        ...oldDim.getPatchForStoryValueTo(newValue)
                    };
                });
                await data.stories.store(reworked);
            }
        }
        const newDim = oldDim?.withoutValue(value);
        await data.dimensions.store([newDim.toRaw()]);
    }
}
