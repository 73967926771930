import { v4 } from 'uuid';
export const teamWork = async () => {
    return {
        board: {
            id: 'default',
            label: 'Team work',
            mode: 'kanban',
            filters: {},
            anchors: {
                displayBy: ['state'],
            },
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            coloredDimension: 'state',
            background: 'default',
            decksOpen: true,
            dimensions: [
                {
                    id: v4(),
                    code: 'state',
                    label: 'State',
                    required: true,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 100000,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff' },
                        { id: 'todo', label: 'Todo', ordering: 100000, color: '#e01514' },
                        { id: 'ongoing', label: 'Ongoing', ordering: 200000, color: '#f77d00' },
                        { id: 'done', label: 'Done', ordering: 300000, color: '#00b048' },
                    ],
                },
                {
                    id: v4(),
                    code: 'assignee',
                    label: 'Assignee',
                    required: false,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 200000,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff' },
                        { id: 'marc', label: 'Marc', ordering: 100000, color: '#ffffff' },
                        { id: 'bernard', label: 'Bernard', ordering: 200000, color: '#ffffff' },
                        { id: 'victor', label: 'Victor', ordering: 300000, color: '#ffffff' },
                    ],
                },
                {
                    id: v4(),
                    code: 'scope',
                    label: 'Scope',
                    required: false,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 300000,
                    values: [
                        { id: null, label: 'No value' },
                        { id: 'marketing', label: 'Marketing', ordering: 100000 },
                        { id: 'product', label: 'Product', ordering: 200000 },
                    ],
                },
            ],
            ganttScale: 'month',
            summaries: [
                {
                    code: 'total',
                    label: '# of Cards',
                    expr: 'count',
                    ordering: 10000,
                },
            ],
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
            workspacePermissions: [{
                    workspace: 'admins',
                    canManage: true,
                    canRead: true,
                    canWrite: true,
                }],
            ordering: 1000000,
            createdBy: 'blambeau',
            createdAt: new Date(),
        },
        stories: [{
                id: v4(),
                identifier: 1,
                title: 'Create an advertisement\non Facebook, LinkedIn, Twitter.',
                state: 'todo',
                scope: 'marketing',
                assignee: 'marc',
            }, {
                id: v4(),
                identifier: 2,
                title: 'Prepare a workshop\nexplaining the best practices in agility.',
                state: 'todo',
                scope: 'product',
                assignee: 'bernard',
            }, {
                id: v4(),
                identifier: 3,
                title: 'Fix the frontend bug\nwhen opening a new pop-up.',
                state: 'todo',
                scope: 'product',
                assignee: 'victor',
            }],
    };
};
