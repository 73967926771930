import { Dimension } from '../../../model';
export class DeleteDimension {
    async run(client, args) {
        const dimId = args.dimension.id;
        await client.store.connector.dimensions.single(dimId).delete();
        const raw = args.dimension.toRaw();
        this._result = Dimension.dress({ ...raw, deleted: true, deletedAt: Date.now() });
    }
    get result() {
        return this._result;
    }
}
;
