export class PersistentPatchStorage {
    constructor(storageId, preferences) {
        this.storageId = storageId;
        this.preferences = preferences;
        const found = preferences.get(storageId);
        if (!found) {
            preferences.set(storageId, {});
        }
    }
    async store(patches) {
        const current = await this.getStorage();
        const newPref = patches.reduce((map, patch) => {
            map[patch.id] = patch;
            return map;
        }, current);
        await this.preferences.set(this.storageId, newPref);
    }
    async delete(patches) {
        const current = await this.getStorage();
        const newPref = patches.reduce((map, patch) => {
            delete map[patch.id];
            return map;
        }, current);
        return this.preferences.set(this.storageId, newPref);
    }
    async deleteByID(id) {
        const current = await this.getStorage();
        delete current[id];
        await this.preferences.set(this.storageId, current);
    }
    async has(patch) {
        const current = await this.getStorage();
        return !!current[patch.id];
    }
    async get(id) {
        const current = await this.getStorage();
        return current?.[id];
    }
    async clear() {
        return this.preferences.set(this.storageId, {});
    }
    async entries() {
        const current = await this.getStorage();
        return Object.values(current);
    }
    async getStorage() {
        const found = await this.preferences.get(this.storageId);
        return found || {};
    }
}
