import { examples } from '@klaro/corejs/examples';
import { Project, Session } from '@klaro/corejs/model';
import { createKlaroStore, factorConnector, KlaroClient, KlaroConnector, KlaroStoreProvider, MemoryStore, LocalStorageStore, PersistentPatchStorage, SetBoardState, } from '@klaro/corejs/client';
export const PlaygroundProject = {
    id: '589c3324-6574-400a-8361-3c8d9df6a96e',
    name: 'Klaro Cards',
    subdomain: 'playground',
    isPublic: true,
    isTemplate: false,
    isBlocked: false,
    isLocked: false,
    timezone: 'Europe/Brussels',
    locale: 'en-BE',
    url: 'https://playground.klaro.cards',
    logoUrl: null,
    revision: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    logoThumbUrl: undefined,
};
angular
    .module('klaro.kernel')
    .constant('KernelConstants', {})
    .service('KernelPlayground', KernelPlayground);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelPlayground($log, $window, KernelConstants) {
    async function init() {
        $log.info('Booting the Klaro Playground...');
        const storeProvider = new KlaroStoreProvider(() => KernelConstants.store, (newStore) => { KernelConstants.store = newStore; });
        KernelConstants.client = new KlaroClient(storeProvider)
            .withPreferencesStore(new MemoryStore())
            .withDefaultEventually();
        const storeData = await loadInitialStoreData(KernelConstants.client);
        KernelConstants.store = createKlaroStore(KernelConstants.client, storeData);
        KernelConstants.client.init();
        installEvents(KernelConstants.client);
        await KernelConstants.client.boot();
        return Promise.resolve(KernelConstants.client);
    }
    async function loadInitialStoreData(client) {
        const pathname = $window.location.pathname;
        const match_exemples = pathname.match(/^\/exemples\/(.*)$/);
        const match_x = pathname.match(/^\/x\/([^\/]+)\/([^\/]+)$/);
        if (match_exemples) {
            return await loadInitialStoreDataFromCampaign(client);
        }
        else if (match_x) {
            return await loadInitialStoreDataFromX(client, match_x[1]);
        }
        else {
            return await loadInitialStoreDataFromExample(client);
        }
    }
    async function loadInitialStoreDataFromCampaign(client) {
        return {
            apiOptions: {
                baseURL: 'https://exemples.klaro.cards/api',
                subdomain: 'exemples',
            },
            connector: factorConnector(client, KlaroConnector.local, {
                feedStrategy: 'api',
            }),
            baseImgUrl: 'https://exemples.klaro.cards',
            project: Project.dress({
                ...PlaygroundProject,
                subdomain: 'exemples',
            }),
            session: new Session({
                user: {
                    workspaces: [{ code: 'admins' }],
                },
                viewAs: 'admins',
            }),
        };
    }
    async function loadInitialStoreDataFromX(client, subdomain) {
        return {
            apiOptions: {
                baseURL: 'https://app.klaro.cards/api',
                subdomain: subdomain,
            },
            connector: factorConnector(client, KlaroConnector.local, {
                feedStrategy: 'api',
            }),
            baseImgUrl: `https://${subdomain}.klaro.cards`,
            project: Project.dress({
                ...PlaygroundProject,
                subdomain: subdomain,
            }),
            session: new Session({
                user: {
                    workspaces: [{ code: 'admins' }],
                },
                viewAs: 'admins',
            }),
        };
    }
    async function loadInitialStoreDataFromExample(client) {
        const exampleKey = 'klaro.playground.baseExample';
        const preferences = new LocalStorageStore();
        const storage = {
            storyPatches: new PersistentPatchStorage('playground.storyPatches', preferences),
            dimensionPatches: new PersistentPatchStorage('playground.dimensionPatches', preferences),
            boardsPatches: new PersistentPatchStorage('playground.boardsPatches', preferences),
            projectsPatches: new PersistentPatchStorage('playground.projectsPatches', preferences),
        };
        const connector = await factorConnector(client, KlaroConnector.local, {
            patchStorage: storage,
            feedStrategy: 'empty',
        });
        const params = new URLSearchParams($window.location.search);
        let reset = params.get('reset');
        if (reset && examples[reset]) {
            // 1. Explicit request to reset the project
            const example = await examples[reset]();
            await connector.clearAll();
            await preferences.set('objectiveShown', {});
            await preferences.set(exampleKey, reset);
            await connector.feedFrom(example);
            $window.history.replaceState(null, '', `${$window.location.protocol}//${$window.location.hostname}/`);
        }
        else {
            reset = await preferences.get(exampleKey);
            if (reset) {
                // 2. Example has been previously installed, keep it
            }
            else {
                // 3. No example installed yet, install onboarding
                reset = 'onboarding';
                await preferences.set('objectiveShown', {});
                await preferences.set('openInfoAndContactModal', 'yes');
                await preferences.set(exampleKey, reset);
                const example = await examples[reset]();
                await connector.feedFrom(example);
            }
        }
        return {
            connector: connector,
            baseImgUrl: 'https://blog.klaro.cards',
            project: Project.dress({
                ...PlaygroundProject,
                subdomain: 'playground',
            }),
            session: new Session({
                user: {
                    workspaces: [{ code: 'admins' }],
                },
                viewAs: 'admins',
            }),
        };
    }
    function get() {
        return KernelConstants.client;
    }
    function installEvents(client) {
        if (client.store.project?.subdomain !== 'playground') {
            return;
        }
        client.subscribe(SetBoardState, async (_op, _args) => {
            await client.store.board?.save();
        });
    }
    // This is the service API
    return {
        init: init,
        get: get,
    };
}
