import { ApiSeshatConnector } from '../api/ApiSeshatConnector';
export class LocalSeshatConnector extends ApiSeshatConnector {
    constructor(connector, client) {
        super(client);
        this.connector = connector;
        this.client = client;
    }
    async create(_attachments) {
        throw new Error('LocalSeshatConnector#create() not implemented.');
    }
}
