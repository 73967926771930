import { DataObject, ArrayUtils, Patcher, Markdowner } from '../utils/';
import { FakeKind, Identifier as IdentifierDim, RecentlyUpdated } from './Dimensions';
import Dimension from './Dimension';
import Filters from './Filters';
import Story from './Story';
import Summary from './Summary';
import BoardAnchor from './BoardAnchor';
import WorkspacePermissions from './Board/WorkspacePermissions';
import FileUtils from '../utils/FileUtils';
import { DisplayModeCode } from './types';
export const DisplayModes = [
    {
        code: DisplayModeCode.Grid,
        label: 'Grid',
        maxCardDimensions: 8,
        icon: 'icon-View-Grid',
        supportsDisplayByAnchor: false,
        supportsCompactDisplay: false,
        supportsSwipeGestures: false,
        supportsSummarizers: false,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.Kanban,
        label: 'Kanban',
        maxCardDimensions: 4,
        icon: 'icon-View-Kanban',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: false,
        supportsCompactDisplay: true,
        groupWording: 'columns',
        supportsSwipeGestures: false,
        supportsSummarizers: true,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.Matrix,
        label: 'Matrix',
        maxCardDimensions: 4,
        icon: 'icon-View-Matrix',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: false,
        supportsCompactDisplay: true,
        groupWording: 'columns or rows',
        supportsSwipeGestures: false,
        supportsSummarizers: false,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.List,
        label: 'List',
        maxCardDimensions: 25,
        icon: 'icon-View-List',
        supportsDisplayByAnchor: false,
        supportsCompactDisplay: false,
        supportsSwipeGestures: false,
        supportsSummarizers: false,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.Gallery,
        label: 'Gallery',
        maxCardDimensions: 8,
        icon: 'icon-View-Gallery',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: false,
        supportsCompactDisplay: false,
        supportsSwipeGestures: false,
        supportsSummarizers: false,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.Gantt,
        label: 'Gantt',
        maxCardDimensions: 8,
        icon: 'icon-View-Gantt',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: true,
        supportsCompactDisplay: true,
        groupWording: 'groups',
        supportsSwipeGestures: false,
        supportsSummarizers: false,
        includeInPlayground: false,
    },
    {
        code: DisplayModeCode.Casino,
        label: 'Casino',
        maxCardDimensions: 4,
        icon: 'icon-View-Casino',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: false,
        supportsCompactDisplay: false,
        supportsSwipeGestures: true,
        supportsSummarizers: true,
        includeInPlayground: true,
    },
    {
        code: DisplayModeCode.Chart,
        label: 'Chart',
        maxCardDimensions: 4,
        icon: 'icon-View-Chart',
        supportsDisplayByAnchor: true,
        displayByNoneIsSupported: false,
        supportsCompactDisplay: true,
        groupWording: 'categories',
        supportsSwipeGestures: false,
        supportsSummarizers: true,
        includeInPlayground: true,
    },
];
class Board extends DataObject {
    constructor(raw) {
        super(raw, undefined, (prop) => {
            return prop !== 'coloredDimension' && prop !== 'stories';
        });
    }
    static get properties() {
        return [
            'id',
            'label',
            'location',
            'mode',
            'objective',
            'objectiveModal',
            'decksOpen',
            'compactDisplay',
            'compactDecks',
            'explorerDecks',
            'manualDimensionsInDecks',
            'displayDimensionLabels',
            'displayDimensionsOnCards',
            'displayNoneOnCards',
            'background',
            'summaries',
            'createdBy',
            'createdAt',
            'search',
            'connector',
        ];
    }
    static dress(raw) {
        return new Board(Board.dressSubs(raw));
    }
    static dressSubs(subs) {
        subs = Object.assign({}, subs);
        if (subs.dimensions) {
            subs.dimensions = subs.dimensions.map(Dimension.dress);
        }
        if (subs.filters) {
            subs.filters = Filters.dress(subs.filters, { dimensions: subs.dimensions });
        }
        if (subs.summaries) {
            subs.summaries = subs.summaries.map(Summary.dress);
        }
        if (subs.workspacePermissions) {
            subs.workspacePermissions = WorkspacePermissions.dress(subs.workspacePermissions);
        }
        if (subs.extraSettings) {
            subs.extraSettings = {
                ...subs.extraSettings
            };
        }
        return subs;
    }
    isAllCards() {
        return this.location === 'all';
    }
    // Facade over mode
    /** Returns available display modes. */
    static getDisplayModes() {
        return DisplayModes;
    }
    static getDisplayMode(mode) {
        return Board.getDisplayModes().find(m => m.code === mode);
    }
    getDisplayModes() {
        return this.availableDisplayModes || DisplayModes;
    }
    withAvailableDisplayModes(modes) {
        return this.clone({ availableDisplayModes: modes });
    }
    getDisplayMode() {
        return this.getDisplayModes().find(m => m.code === this.mode);
    }
    isMode(mode) {
        return this.mode === mode;
    }
    withMode(mode) {
        return this.clone({ mode: mode });
    }
    switchToKanbanBy(dimension) {
        return this
            .setDimensionsAt('displayBy', [dimension])
            .withMode('kanban');
    }
    switchToCasinoBy(dimension) {
        return this
            .setDimensionsAt('displayBy', [dimension])
            .withMode('casino');
    }
    switchToGrid() {
        return this
            .withMode('grid');
    }
    switchToGantt(scale, dimensions) {
        return this
            .withGanttScale(scale)
            .setDimensionsAt('gantt', dimensions.filter(Boolean))
            .withMode('gantt');
    }
    switchToOnboardedState(newValue) {
        const kind = this.getKindDimension();
        return this
            .withColoredDimension(kind)
            .setFilter(kind, newValue)
            .withMode('grid');
    }
    withLabel(label) {
        return this.clone({ label: label });
    }
    withObjective(objective) {
        return this.clone({ objective: objective });
    }
    withLocation(location) {
        return this.clone({ location: location });
    }
    withCompactDisplay(compact = true) {
        return this.clone({ compactDisplay: compact });
    }
    withAnchors(anchors) {
        return this.clone({ anchors: anchors });
    }
    withColoredDimension(dim) {
        if (dim.code) {
            dim = dim.code;
        }
        return this.clone({ coloredDimension: dim });
    }
    withGanttScale(scale) {
        return this.clone({ ganttScale: scale });
    }
    withDecksState(open) {
        return this.clone({ decksOpen: open });
    }
    toggleDecks() {
        return this.clone({ decksOpen: !this.decksOpen });
    }
    toggleDecksCompactness() {
        return this.clone({ compactDecks: !this.compactDecks });
    }
    toggleDecksExplorer() {
        return this.clone({ explorerDecks: !this.explorerDecks });
    }
    withBackground(background) {
        return this.clone({ background: background });
    }
    withSwipeGestures(swipeGestures) {
        return this.clone({
            extraSettings: {
                ...this.extraSettings,
                swipeGestures
            }
        });
    }
    withChartOptions(chartOptions) {
        return this.clone({
            extraSettings: {
                ...this.extraSettings,
                chartOptions
            }
        });
    }
    withoutSwipeGestures() {
        const extra = { ...this.extraSettings };
        delete extra['swipeGestures'];
        return this.clone({
            extraSettings: extra
        });
    }
    // Facade over dimension holders
    getStoryColor(story, globalContext) {
        const dim = this.coloredDimension();
        if (dim) {
            return dim.getStoryColor(story, globalContext);
        }
    }
    /** Returns dimensions that are candidate for the colored dimension. */
    coloredDimensions() {
        return this.dimensions.filter((d) => {
            return d.colored;
        });
    }
    // @ts-expect-error fix typescript
    coloredDimension() {
        return this.__raw.coloredDimension ?
            this.dimension(this.__raw.coloredDimension) :
            undefined;
    }
    addNewDimension(dimension) {
        let dims = this.dimensions.slice();
        if (dims.find(d => d.code === dimension.code)) {
            dims = ArrayUtils.replace(dims, (d) => d.code === dimension.code, dimension);
        }
        else {
            dims.push(dimension);
        }
        return this.clone({ dimensions: dims });
    }
    getDimensions() {
        return this.dimensions;
    }
    getDateBoundaryDimensions() {
        return this.dimensions.filter((d) => d.canBeUsedAsDateBoundary());
    }
    dimension(codeOrDim) {
        if (codeOrDim.fakeDimension) {
            return codeOrDim;
        }
        const code = codeOrDim.code ? codeOrDim.code : codeOrDim;
        return this.memoize2('dimensionsByCode', () => {
            return this.dimensions.reduce((memo, d) => {
                memo[d.code] = d;
                return memo;
            }, {});
        }, ['dimensions'])[code];
    }
    dimensionById(id) {
        return this.dimensions.find(d => d.id === id);
    }
    getAnchorAt(anchor) {
        const list = (this.anchors || {})[anchor] || [];
        return BoardAnchor.list(list, { dimensions: this.dimensions });
    }
    getDimensionsAt(anchor) {
        const dimCodes = (this.anchors || {})[anchor] || [];
        return dimCodes.map((code) => { return this.dimension(code); });
    }
    getDimensionAt(anchor) {
        return this.getDimensionsAt(anchor)[0];
    }
    hasDimensionsAt(anchor, dims) {
        const curs = this.getDimensionsAt(anchor);
        return ArrayUtils.isSamelist(dims, curs);
    }
    addDimensionAt(anchor, codeOrDim) {
        const dimension = this.dimension(codeOrDim);
        if (!dimension) {
            return this;
        }
        const anchors = Object.assign({}, this.anchors);
        anchors[anchor] = ArrayUtils.union([dimension.code], anchors[anchor] || []);
        return this.clone({ anchors: anchors });
    }
    setDimensionsAt(anchor, dimensions) {
        const anchors = Object.assign({}, this.anchors);
        anchors[anchor] = dimensions.map((d) => {
            return this.dimension(d).code;
        });
        return this.clone({ anchors: anchors });
    }
    addDimensionValue(codeOrDim, value, removeCompactMode = false) {
        const dimension = this.dimension(codeOrDim);
        if (dimension) {
            const dimensions = ArrayUtils.replace(this.dimensions, (d) => {
                return d.code === dimension.code;
            }, dimension.addValue(value));
            return this.clone({
                dimensions: dimensions,
                compactDisplay: removeCompactMode ? false : this.compactDisplay,
            });
        }
        else {
            const code = codeOrDim.code ? codeOrDim.code : codeOrDim;
            throw new Error(`No such dimension ${code}`);
        }
    }
    withUpdatedDimension(dimension) {
        const matcher = (d) => d.id === dimension.id;
        const oldDimension = this.dimensions.find(matcher);
        if (!oldDimension)
            return this;
        // Rename dimension
        const dimensions = ArrayUtils.replace(this.dimensions, matcher, dimension);
        // Rename anchors
        let anchors = this.anchors;
        if (oldDimension.code !== dimension.code) {
            anchors = Object.keys(this.anchors).reduce((as, k) => {
                as[k] = ArrayUtils.replace(this.anchors[k], (code) => code === oldDimension.code, dimension.code);
                return as;
            }, {});
        }
        // Rename filters
        let filters = this.filters;
        if (oldDimension.code !== dimension.code) {
            filters = this.filters.withDimensionRenamed(oldDimension.code, dimension.code);
        }
        return this.clone({
            dimensions: dimensions,
            anchors: anchors,
            filters: filters,
        });
    }
    withDeletedDimension(dimension) {
        const matcher = (d) => d.id === dimension.id;
        const oldDimension = this.dimensions.find(matcher);
        if (!oldDimension)
            return this;
        // Remove dimension
        const dimensions = ArrayUtils.without(this.dimensions, oldDimension);
        // Remove from anchors
        const anchors = Object.keys(this.anchors).reduce((as, k) => {
            as[k] = ArrayUtils.without(this.anchors[k], dimension.code);
            return as;
        }, {});
        // Remove from filters
        const filters = this.filters.withDimensionRemoved(dimension.code);
        return this.clone({
            dimensions: dimensions,
            anchors: anchors,
            filters: filters,
        });
    }
    isRequired(codeOrDim) {
        const dimension = this.dimension(codeOrDim);
        return dimension && (dimension.required || this.getAnchorAt('required').has(dimension));
    }
    withDimensionRequired(codeOrDim) {
        return this.addDimensionAt('required', codeOrDim);
    }
    toggleDisplayDimensionsOnCards() {
        return this.clone({
            displayDimensionsOnCards: !this.displayDimensionsOnCards
        });
    }
    toggleDisplayNoneOnCards() {
        return this.clone({
            displayNoneOnCards: !this.displayNoneOnCards
        });
    }
    getCardDimensions() {
        return this.memoize2('getCardDimensions', () => {
            if (this.displayDimensionsOnCards) {
                const relevant = (d) => d.canBeUsedOnCards() && this.isDimensionRelevantWrtFilters(d) && !d.isCoverImage();
                const wanted = this.getDimensionsAt('cards');
                if (wanted && wanted.length > 0) {
                    // user chooses himself, return them filtered on relevant ones
                    return wanted.filter(relevant);
                }
                else {
                    // user did not choose, let's be smart
                    const candidates = this.getDimensions()
                        .filter(relevant)
                        .filter(d => !d.shouldBeHiddenOnCardsByDefault())
                        .sort((d, e) => d.ordering - e.ordering);
                    return candidates.slice(0, this.getDisplayMode().maxCardDimensions || 25);
                }
            }
            else {
                return [];
            }
        }, ['dimensions', 'mode', 'anchors']);
    }
    getCardDimensionsFor(story) {
        const kindDim = this.getKindDimension();
        const kind = kindDim.getStoryRawValue(story);
        return this.memoize2(`getCardDimensionsFor(${kind})`, () => {
            const dims = this.getCardDimensions();
            return dims.filter((d) => d.isRelevant(story, this));
        }, ['dimensions']);
    }
    getInvalidStoryDimensions(story) {
        if (!story)
            return [];
        // TODO: this feels like it can be improved
        return this.getDimensions()
            .filter((d) => {
            const storyValue = d.getStoryRawValue(story);
            return d.userEditable
                && d.isRelevant(story, this)
                && d.canBeEditedAsCardDimension()
                && this.isRequired(d)
                && (storyValue === null ||
                    storyValue === undefined ||
                    typeof storyValue === 'string' && storyValue.trim() === '');
        });
    }
    isStoryValid(story) {
        if (!story)
            return false;
        const missingRequiredCardDimensions = this.getInvalidStoryDimensions(story);
        return missingRequiredCardDimensions.length === 0
            && !!story.hasTitle();
    }
    setManualDimensionsInDecks(value) {
        return this.clone({
            manualDimensionsInDecks: value,
        });
    }
    getDeckDimensionCandidates() {
        return this.getDimensions()
            .filter(d => d.shouldBeShownOnDecks(this))
            .filter(d => this.isDimensionRelevantWrtFilters(d))
            .sort((d, e) => d.ordering - e.ordering);
    }
    getDeckDimensions() {
        const manual = this.manualDimensionsInDecks;
        if (manual) {
            return this.getDimensionsAt('decks');
        }
        else {
            return this.getDeckDimensionCandidates();
        }
    }
    getDefaultExportDimensions() {
        return [this.getTitleDimension(), ...this.getCardDimensions()];
    }
    getRelevantDimensionsForAnchor(anchor) {
        return this.getDimensions().filter((d) => {
            return !d.attribute &&
                this.isDimensionRelevantWrtFilters(d) &&
                d.canBeUsedForAnchor(anchor);
        });
    }
    /**
     * Returns True if
        * The board is not filtered on Kind
        * OR the board is filtered on Kind AND
          * The dimension is not restricted to some kind(s)
          * OR the dimension is restricted to some kind(s) and it matches the filtered kind(s)
     * @param {Dimension} dim
     * @returns {boolean}
     */
    isDimensionRelevantWrtFilters(dim) {
        const kindDimension = this.getKindDimension();
        if (this.hasFilter(kindDimension)) {
            const filteredKinds = this.getFilters()
                .getFilter(kindDimension)
                .map((fk) => {
                return fk.id;
            });
            return !Object.prototype.hasOwnProperty.call(dim, 'relevantKinds')
                || dim.relevantKinds.length === 0
                || ArrayUtils.intersects(filteredKinds, dim.relevantKinds);
        }
        return true;
    }
    /** Returns the unique dimension that defines the identifiers of the cards. */
    getIdentifierDimension() {
        return this.memoize2('getIdentifierDimension', () => {
            const k = this.getDimensions().find((dim) => {
                return dim.datatype === 'Identifier';
            });
            return k ? k : IdentifierDim.instance();
        }, ['dimensions']);
    }
    /** Returns the unique dimension that defines the nature of the cards. */
    getKindDimension() {
        return this.memoize2('getKindDimension', () => {
            const k = this.getDimensions().find((d) => {
                return d.datatype === 'Kind';
            });
            return k ? k : FakeKind.instance();
        }, ['dimensions']);
    }
    getCreatedAtDimension() {
        return this.memoize2('getCreatedAtDimension', () => {
            return this.getDimensions().find(d => d.datatype === 'RecentlyCreated');
        }, ['dimensions']);
    }
    getUpdatedAtDimension() {
        return this.memoize2('getUpdatedAtDimension', () => {
            const dim = this.getDimensions().find(d => d.datatype === 'RecentlyUpdated');
            return dim ?? RecentlyUpdated.instance();
        }, ['dimensions']);
    }
    getPlaceholderFor(storyOrData, defaultPlaceholder = 'A card title on the first line.\nUse the following lines for a longer summary if needed.') {
        const kindDimension = this.getKindDimension();
        if (!kindDimension) {
            return defaultPlaceholder;
        }
        const value = kindDimension.getStoryHighLevelValue(storyOrData);
        return value && value.placeholder || defaultPlaceholder;
    }
    /** Returns the unique dimension that defines the card title. */
    getTitleDimension() {
        return this.memoize2('getTitleDimension', () => {
            return this.getDimensions().find((d) => {
                return d.datatype === 'Title';
            });
        }, ['dimensions']);
    }
    /** Returns the unique dimension that defines the cover image. */
    getCoverImageDimension() {
        return this.memoize2('getCoverImageDimension', () => {
            return this.getDimensions().find((d) => {
                return d.datatype === 'CoverImage';
            });
        }, ['dimensions']);
    }
    getArchivedDimension() {
        return this.memoize2('getArchivedDimension', () => {
            return this.getDimensions().find((d) => {
                return d.datatype === 'Archived';
            });
        }, ['dimensions']);
    }
    /** Returns whether the board shows the cover image. */
    showCoverImage() {
        const dimension = this.getCoverImageDimension();
        if (!dimension) {
            return false;
        }
        const anchor = this.getAnchorAt('cards');
        return anchor && anchor.has(dimension);
    }
    hasBinaryLinkDimension() {
        return this.getDimensions().some((d) => {
            return d.datatype === 'StoryBinaryLink';
        });
    }
    getCardKindHumanTitle() {
        const kindDim = this.getKindDimension();
        if (!kindDim)
            return 'Card';
        const values = this.filterValuesAlong(kindDim);
        if (!values || values.length !== 1)
            return 'Card';
        return values[0].label;
    }
    // Facade over story ordering
    getOrderingDimensions() {
        return this
            .dimensions
            .filter((d) => d.canBeUsedForOrdering())
            .sort(ArrayUtils.sorterBy(['label']));
    }
    getOrderingAnchor() {
        return this.getAnchorAt('ordering');
    }
    isSortedBy(codeOrDim) {
        const dimension = this.dimension(codeOrDim);
        return this.getOrderingAnchor().has(dimension);
    }
    isSortedByAsc(codeOrDim) {
        const dimension = this.dimension(codeOrDim);
        const ordering = this.getOrderingAnchor().get(dimension);
        return !!ordering && ordering.sign;
    }
    isSortedByDesc(codeOrDim) {
        const dimension = this.dimension(codeOrDim);
        const ordering = this.getOrderingAnchor().get(dimension);
        return !!ordering && !ordering.sign;
    }
    sortBy(codeOrDim, ascending = true, force = true) {
        if (typeof (codeOrDim) === 'string') {
            const sign = codeOrDim.substring(0, 1);
            if (sign === '+') {
                return this.sortBy(codeOrDim.substring(1), true);
            }
            else if (sign === '-') {
                return this.sortBy(codeOrDim.substring(1), false);
            }
        }
        if (ascending === undefined) {
            ascending = true;
        }
        const dimension = this.dimension(codeOrDim);
        if (force || !this.isSortedBy(dimension)) {
            const part = ascending ? dimension.code : (`-${dimension.code}`);
            const ordering = { ordering: [part] };
            return this.clone({
                anchors: Object.assign({}, this.anchors || {}, ordering),
            });
        }
        else {
            return this;
        }
    }
    getStoriesSorterParts(globalContext) {
        const identifier = this.dimension('identifier');
        let anchor = this.getAnchorAt('ordering');
        if (identifier && !anchor.has(identifier)) {
            anchor = anchor.add(identifier);
        }
        return anchor.toStorySorter(globalContext);
    }
    getStoriesSorter(globalContext) {
        return ArrayUtils.sorterBy(this.getStoriesSorterParts(globalContext));
    }
    getLinkedStoriesFor(story) {
        return story.linkedStories(this.getDimensions());
    }
    // Facade over objectives
    hasLongObjective() {
        return this.objective && this.objective.includes('\n\n');
    }
    shortObjectiveHtml() {
        return this.memoize2('shortObjectiveHtml', () => {
            const obj = this.objective;
            if (!obj || obj === '') {
                return '';
            }
            const split = obj.split('\n\n');
            let str = split[0];
            if (split.length > 1) {
                str = str + ' [...]';
            }
            return Markdowner.light.render(str);
        }, ['objective']);
    }
    // Facade over custom order
    getCustomOrder() {
        return this.dimensions.find((d) => {
            return d.datatype === 'CustomOrder';
        });
    }
    hasCustomOrder() {
        return !!this.getCustomOrder();
    }
    usesCustomOrder() {
        const dim = this.getCustomOrder();
        return !!(dim && this.isSortedBy(dim));
    }
    // Facade over stories
    hasSearch() {
        return this.search && this.search.trim().length > 0;
    }
    withSearch(search) {
        return this.clone({
            search: search && search.trim().length > 0 ? search : undefined,
        });
    }
    withoutSearch() {
        return this.clone({
            search: undefined,
            filters: this.filters.remove({ code: '_q' }, { id: null }),
        });
    }
    withPageSize(pageSize) {
        return this.clone({
            pageSize: pageSize,
        });
    }
    getDimensionValuesOn(dimension, story, globalContext) {
        dimension = this.dimension(dimension);
        return dimension.matchingValuesOf(story, globalContext);
    }
    // Facade over filters
    getFilters() {
        return this.filters || Filters.dress({}, {
            dimensions: this.dimensions
        });
    }
    visibleFilters() {
        return this.memoize2('visibleFilters', () => {
            const fs = this.getFilters();
            const dims = this.getDeckDimensions();
            return dims.length ? fs.project(dims) : fs;
        }, ['dimensions', 'anchors']);
    }
    invisibleFilters() {
        const fs = this.getFilters();
        const dims = this.getDeckDimensions();
        return dims.length ? fs.allbut(dims) : fs.clear();
    }
    clearFilters() {
        return this.clone({
            filters: this.invisibleFilters(),
        });
    }
    hasFilter(dimension) {
        return this.filters && this.filters.hasFilter(dimension);
    }
    isFilteredBy(dimension, value) {
        return this.filters.isFilteredBy(dimension, value);
    }
    setFilter(dimension, value) {
        dimension = this.dimension(dimension);
        return this.clone({ filters: this.filters.set(dimension, value) });
    }
    setFilters(filters) {
        return this.clone({ filters: filters });
    }
    addFilter(dimension, value) {
        return this.clone({ filters: this.filters.add(dimension, value) });
    }
    overrideFilters(pairs) {
        const newFilters = pairs.reduce((memo, pair) => {
            return memo.set(pair[0], pair[1]);
        }, this.filters);
        return this.clone({ filters: newFilters });
    }
    removeFilter(dimension, value) {
        return this.clone({ filters: this.filters.remove(dimension, value) });
    }
    toggleFilter(dimension, value) {
        return this.clone({ filters: this.filters.toggle(dimension, value) });
    }
    removeDimensionFilters(dimension) {
        return this.clone({ filters: this.filters.removeAll(dimension) });
    }
    filterValuesAlong(dimension) {
        return this.filters.getAlong(dimension);
    }
    isBlackHole(dimension, value) {
        return this.hasFilter(dimension) && !this.isFilteredBy(dimension, value);
    }
    // Card actions
    getCardClickAction() {
        return this.extraSettings?.onCardClick || { type: 'fullEdit', options: {} };
    }
    // Summaries
    hasSummaries() {
        return !!this.summaries?.length;
    }
    getTotalCardsSummary() {
        return this.summaries?.find(s => s.expr === 'count');
    }
    hasSummary(summary, dim) {
        const sum = Summary.factor(summary, dim);
        return this.summaries && !!this.summaries.find((s) => s.isSameSummaryAs(sum));
    }
    hasSummaryUsing(dim) {
        return this.summaries && !!this.summaries.find((s) => s.isDimensionUsed(dim));
    }
    withSummaries(summaries) {
        return this.clone({ summaries: summaries });
    }
    withSummary(summary) {
        return this.withSummaries([summary]);
    }
    // Dashboard
    forDashboard(type) {
        const kind = this.getKindDimension();
        const createdAt = this.getCreatedAtDimension();
        const updatedAt = this.getUpdatedAtDimension();
        const tabs = {
            created: (b) => {
                return b
                    .sortBy(createdAt, false)
                    .setDimensionsAt('cards', [kind.code, createdAt.code]);
            },
            updated: (b) => {
                return b
                    .sortBy(updatedAt, false)
                    .setDimensionsAt('cards', [kind.code, updatedAt.code]);
            },
            search: (b) => {
                return b
                    .sortBy(createdAt, false)
                    .setDimensionsAt('cards', [kind.code, createdAt.code]);
            },
        };
        return tabs[type](this).withPageSize(12);
    }
    // Permissions
    userLevels(includeForbidden = false) {
        return this.workspacePermissions.userLevels(includeForbidden);
    }
    ensureWorkspacePermission(perm) {
        return this.clone({ workspacePermissions: this.workspacePermissions.ensure(perm) });
    }
    getLevelDetailsForWorkspace(workspace) {
        return this.workspacePermissions.getLevelDetailsForWorkspace(workspace);
    }
    isForbiddenTo(workspace) {
        return this.workspacePermissions.getLevelDetailsForWorkspace({ code: workspace }).code === 'forbidden';
    }
    getWorkspaceCodes() {
        return this.workspacePermissions.getWorkspaceCodes();
    }
    // Extra settings
    withExtraSettings(settings) {
        return this.clone({
            extraSettings: settings,
        });
    }
    // Links
    get _links() {
        return {
            self: `/boards/${this.location}`,
        };
    }
    // Helpers for creating new stories
    newStory(extra, globalContext) {
        extra ?? (extra = {});
        const handleOne = (attrs, copyMultiple) => {
            return (memo, key) => {
                const dim = this.dimension(key);
                if (!dim) {
                    // no dimension => a base attribute
                    return Object.assign(memo, { [key]: attrs[key] });
                }
                else {
                    const values = ArrayUtils.dress(attrs[key]);
                    if (values.length > 1) {
                        if (copyMultiple) {
                            // for duplicate story to work on extra
                            return Object.assign(memo, { [key]: attrs[key] });
                        }
                        else {
                            // multiple filter, nothing we can do
                            return memo;
                        }
                    }
                    else {
                        // find the corresponding dimension value
                        const valueId = values[0];
                        const value = dim.getValueById(valueId);
                        if (valueId === null) {
                            return Object.assign(memo, { [key]: null });
                        }
                        else if (value === null || value === undefined) {
                            // no value => a raw value, use it
                            return Object.assign(memo, { [key]: attrs[key] });
                        }
                        else {
                            // get the update and merge it
                            const update = dim.getPatchForStoryValueTo(value, globalContext);
                            return Object.assign(memo, update);
                        }
                    }
                }
            };
        };
        let values = this.filters.toCnf();
        values = Object.keys(values).reduce(handleOne(values, false), {});
        values = Object.keys(extra).reduce(handleOne(extra, true), values);
        this
            .dimensions
            .filter((d) => !Object.prototype.hasOwnProperty.call(values, d.code))
            .forEach((dim) => {
            const def = dim.defaultValue(globalContext || {});
            if (def) {
                values[dim.code] = def;
            }
        });
        return new Story(values, { dimensions: this.dimensions });
    }
    // Towards saving the board
    toFileName(extension) {
        return FileUtils.toFileName(this.label, extension);
    }
    toBoardSaveRaw() {
        if (this._toBoardSaveRaw === undefined) {
            const raw = this.toRaw();
            delete raw.stories;
            delete raw.dimensions;
            delete raw._links;
            delete raw.availableDisplayModes;
            this._toBoardSaveRaw = raw;
        }
        return this._toBoardSaveRaw;
    }
    getPatchAgainst(original, overrides = {}) {
        const originalRaw = original.toBoardSaveRaw();
        const mineRaw = this.toBoardSaveRaw();
        const tobesaved = Object.assign({}, mineRaw, overrides);
        return (new Patcher()).shallow(originalRaw, tobesaved);
    }
    neutralSkeleton() {
        const kind = this.getKindDimension();
        const identifier = this.getIdentifierDimension();
        const coverImage = this.getCoverImageDimension();
        const cardDimensions = [
            identifier,
            kind,
            coverImage,
        ].filter(Boolean).map(dim => dim.code);
        const totalCards = this.getTotalCardsSummary();
        const neutralValues = {
            mode: 'list',
            background: 'default',
            decksOpen: true,
            displayDimensionsOnCards: true,
            compactDecks: false,
            explorerDecks: false,
            objectiveModal: false,
            compactDisplay: false,
            displayDimensionLabels: false,
            manualDimensionsInDecks: false,
            coloredDimension: null,
            anchors: {
                cards: cardDimensions,
                ordering: [],
            },
            filters: {
                archived: ['no'],
            },
            summaries: [
                totalCards
            ].filter(Boolean),
            extraSettings: {}
        };
        return this.clone(neutralValues);
    }
    // Private technical tools
    clone(override) {
        const dupData = Board.dressSubs(Object.assign({}, this.__raw, override));
        return super.clone(dupData);
    }
}
export default Board;
