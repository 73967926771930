import { Markdowner } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .directive('blackholeExplain', blackholeExplain);
function blackholeExplain(quickTipModal) {
    return {
        restrict: 'E',
        template: require('@/support/blackholeExplain.html'),
        scope: {
            cell: '=',
            class: '@',
        },
        replace: true,
        link: function (scope, _elm, _attrs) {
            scope.blackHoleTooltipText = '';
            function init(cell) {
                if (!cell) {
                    return;
                }
                scope.blackHoleTooltipText = cell.blackHoleTooltipText;
                scope.blackHoleTooltipTextHtml = Markdowner.light.render(cell.blackHoleTooltipText);
            }
            init();
            scope.$watch('cell', init);
            scope.explainWhy = function ($event) {
                $event.stopPropagation();
                quickTipModal.open({
                    titleIcon: 'icon-Info',
                    title: 'The Disappearing Drop',
                    message: [
                        scope.blackHoleTooltipText,
                        'Therefore cards dropped on this value get excluded from the board and disappear.',
                    ].join('\n\n'),
                    hints: [
                        {
                            text: 'Protip: try using this feature to free your mind.',
                            icon: 'icon-Goals',
                        },
                        {
                            text: 'Hint: you can also hide columns with no cards.',
                            icon: 'icon-Info',
                        },
                    ],
                    shakeItOnClose: 'board-appearance-entrypoint',
                });
                return false;
            };
        },
    };
}
