import { BoardState } from '../../../state';
import { SetWorkspace } from '../Auth';
export class LoadBoard {
    async run(client, args) {
        if (args.workspace) {
            await client.run(new SetWorkspace, { workspace: args.workspace });
        }
        const canPrefetch = !args.preparer;
        const restHandler = client.store.connector.boards.single(args.boardId);
        const boardState = await BoardState.load(restHandler, undefined, canPrefetch, async (bs) => {
            const handlers = [];
            if (args.preparer) {
                handlers.push(args.preparer);
            }
            handlers.push((bs) => {
                return bs.usedBy(client.store.session.user, client.store.session.viewAs);
            });
            return handlers.reduce((boardPromise, handler) => {
                return boardPromise.then((aBoard) => handler(aBoard));
            }, Promise.resolve(bs));
        }, args.mode || 'full');
        return { board: boardState };
    }
}
;
