export const blank = async () => {
    return {
        board: {
            id: 'default',
            location: 'default',
            connector: 'BaseConnector',
            label: 'Blank',
            mode: 'grid',
            filters: {},
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            background: 'default',
            objective: '',
            objectiveModal: false,
            decksOpen: true,
            compactDecks: false,
            explorerDecks: false,
            manualDimensionsInDecks: false,
            compactDisplay: false,
            summaries: [],
            anchors: {},
            coloredDimension: null,
            dimensions: [],
            ganttScale: 'month',
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
            workspacePermissions: [{
                    workspace: 'admins',
                    canManage: true,
                    canRead: true,
                    canWrite: true,
                }],
            ordering: 1000000,
            createdBy: 'blambeau',
            createdAt: new Date(),
        },
        stories: [
            {
                id: 'a20cff04-ef33-4581-a515-22c8cd8b7a96',
                identifier: 1,
                title: 'Welcome to Klaro Cards\nthe ultimate tool for managing information in a complex, multi-dimensional world.',
                subtitle: '',
            },
        ],
    };
};
