import { Story } from '../../../model';
export class LoadStory {
    constructor() {
        this.preconditions = [];
    }
    async run(client, args) {
        this._result = await this.load(client, args);
    }
    async load(client, args) {
        const { connector } = client.store;
        const boardState = client.store.board;
        const board = boardState.board;
        let endpoint;
        if (board.connector === 'BaseConnector') {
            endpoint = connector.stories.single(args.id.toString());
        }
        else if (board.isAllCards()) {
            endpoint = connector.boards.single('all').stories.single(args.id.toString());
        }
        else {
            endpoint = connector.boards.single(board.id).stories.single(args.id.toString());
        }
        const storyRaw = await endpoint.get();
        const story = Story.dress(storyRaw, {
            dimensions: board.dimensions
        });
        return story;
    }
    get result() {
        return this._result;
    }
}
;
