import { Title } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
const TYPICAL_NAMES = [
    /name/i,
    /title/i,
    /titre/i,
    /summary/i,
];
const MIN_LENGTH = 10;
const MAX_LENGTH = 250;
export class TitleCandidate extends DimensionCandidate {
    _deliver() {
        return [Title, {
                datatype: 'Title',
            }];
    }
    placeBet() {
        if (this.isMultiple) {
            return 0.0;
        }
        let tooLong = 0.0;
        let tooShort = 0.0;
        let invalidType = 0.0;
        for (const value of this.values.keys()) {
            if (typeof value !== 'string') {
                invalidType += 1.0;
            }
            else if (value.length > MAX_LENGTH) {
                tooLong += 1.0;
            }
            else if (value.length < MIN_LENGTH) {
                tooShort += 1.0;
            }
        }
        const hasATitleName = !!TYPICAL_NAMES.find(r => this.dimName.match(r));
        const initialBet = 1.0 - ((tooLong + tooShort + invalidType) / this.valuesSeen);
        console.log(this.dimName, hasATitleName, tooLong, tooShort, invalidType, initialBet);
        return hasATitleName ? initialBet : initialBet / 2.0;
    }
}
